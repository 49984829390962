import { ColorScheme } from "./interfaces";
import {
    ChartStyle, BLACK, CUSTOMER_POSITIVE_COLOR, CUSTOMER_NEGATIVE_COLOR, CUSTOMER_NEUTRAL_COLOR, CUSTOMER_MARKER_COLOR, CUSTOMER_LINE_COLOR, CUSTOMER_AXIS_COLOR, CUSTOMER_GRIDLINE_COLOR,
    CUSTOMER_DOT_CHART_COLOR, CUSTOMER_MORE_COLORS, CUSTOMER_PY_COLOR, CUSTOMER_PL_COLOR, CUSTOMER_FC_COLOR, CUSTOMER_APPLY_PATTERNS, HIGHLIGHT_COLOR
} from "./constants";

// eslint-disable-next-line max-lines-per-function
export function getColorSchemeFromStyle(chartStyle: ChartStyle): ColorScheme {
    switch (chartStyle) {
        case ChartStyle.Zebra:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.ZebraLight:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#DEDDC8",
                markerColor: "#404040",
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.DrHichert:
            return {
                positiveColor: "#8CB400",
                negativeColor: "#FF0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#b0b0b0",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.PowerBI:
            return {
                positiveColor: "#01B8AA",
                negativeColor: "#FD625E",
                neutralColor: "#374649",
                markerColor: BLACK,
                lineColor: "#374649",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.ColorblindSafe:
            return {
                positiveColor: "#6D97FF",
                negativeColor: "#000000",
                neutralColor: "#CCCCCC",
                markerColor: "#404040",
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.Company:
            return {
                positiveColor: CUSTOMER_POSITIVE_COLOR,
                negativeColor: CUSTOMER_NEGATIVE_COLOR,
                neutralColor: CUSTOMER_NEUTRAL_COLOR,
                markerColor: CUSTOMER_MARKER_COLOR,
                lineColor: CUSTOMER_LINE_COLOR,
                axisColor: CUSTOMER_AXIS_COLOR,
                gridlineColor: CUSTOMER_GRIDLINE_COLOR,
                majorGridlineColor: "#999",
                dotChartColor: CUSTOMER_DOT_CHART_COLOR,
                useCustomScenarioColors: CUSTOMER_MORE_COLORS.toString() === "true",
                previousYearColor: CUSTOMER_PY_COLOR,
                planColor: CUSTOMER_PL_COLOR,
                forecastColor: CUSTOMER_FC_COLOR,
                applyPatterns: CUSTOMER_APPLY_PATTERNS.toString() !== "false",
                highlightColor: HIGHLIGHT_COLOR,
            };
        default:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
    }
}

export function getLighterColor(hex: string): string {
    const color = getColorFromHex(hex);
    const brightness = getBrightness(color);
    const factor = 0.1 + Math.pow((255 - brightness) / 255, 2) * 0.4;
    const lighterColor: Color = {
        r: Math.round(Math.min(255, color.r + 255 * factor)),
        g: Math.round(Math.min(255, color.g + 255 * factor)),
        b: Math.round(Math.min(255, color.b + 255 * factor)),
    };
    return colorToHex(lighterColor);
}

function getColorFromHex(hex: string): Color {
    let result = [];
    if (!hex) {
        hex = BLACK;
    }

    if (hex?.length === 4 || hex?.length === 5) {
        result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})?$/i.exec(hex);
    }
    else {
        result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})*$/i.exec(hex);
    }

    if (!result) {
        return { r: 0, g: 0, b: 0 };
    }
    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    };
}

function getBrightness(color: Color): number {
    return Math.sqrt(color.r * color.r * 0.241 + color.g * color.g * 0.691 + color.b * color.b * 0.068);
}

function colorToHex(color: Color): string {
    return "#" + componentToHex(color.r) + componentToHex(color.g) + componentToHex(color.b);
}

function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export class Color {
    r: number;
    g: number;
    b: number;
}

export function getVarianceColor(invert: boolean, value: number, scheme: ColorScheme): string {
    if (value < 0) {
        if (invert) {
            return scheme.positiveColor;
        }
        return scheme.negativeColor;
    }
    else {
        if (invert) {
            return scheme.negativeColor;
        }
        return scheme.positiveColor;
    }
}
