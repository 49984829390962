import { licensing } from "@zebrabi/licensing/Licensing";
import * as d3 from "../d3";
import { showTooltip } from "../library/ui/showTooltip";
import { getRelativeVarianceLabel, getVarianceDataLabel } from "./../library/formatting";
import { getVarianceColor } from "./../library/styles";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";

import { ChartData, DataPoint, Viewport } from "../interfaces";
import { 
    BLOCK, CENTER, CLICK, COLOR, COLUMN, COLUMN_REVERSE, CommentBoxPlacement, CommentBoxTitle, CommentBoxVariance, DIV, DRAG, DRAGEND, DRAGSTART, FILL_OPACITY, FLEX, FLEX_DIRECTION,
    FONT_FAMILY, GRAY, HEIGHT, MARGIN, MARGIN_BOTTOM, MARGIN_RIGHT, MOUSEOVER, NONE, POINTS, POLYLINE, POSITION, PX, ROW, ROW_REVERSE, SOLID, STROKE, STROKE_DASHARRAY,
    STROKE_WIDTH, VISIBILITY, WIDTH, VISIBLE, P, SPAN, HEADER_DROPDOWN_SETTINGS_ARROW, CONTEXT_MENU, VarianceIcon, FONT_SIZE_UNIT, BOLD, COMMENT_BOX_SHADOW_DEFAULT,
    COMMENT_BOX_MARGIN_DEFAULT, COMMENT_BOX_PADDING_DEFAULT
} from "../library/constants";
import { ChartSettings } from "./../settings/chartSettings";
import { createSvgElement, drawLine, drawVarianceIcon, getBoundingBoxHtml } from "../library/drawing";
import { ChartType } from "../enums";
import { RELATIVE } from "../consts";
import { Settings, DropdownElements, SwitchButton, Button, InputElements } from "@zebrabi/design-library";
import { FREE_MODE_COMMENT_LIMIT } from "../../tables/library/constants";
import { getFormattedDataLabel, getPercentageFormatOrNull } from "@zebrabi/zebrabi-core";
import { Visual } from "../visual";

export default class InfoBoxHandler {
    settings: ChartSettings;
    target: HTMLElement;
    infoBoxContainer: HTMLElement;
    dragLineElement: HTMLElement;
    hostElement: HTMLElement;
    isSingleMeasure: boolean = false;
    availableInfoBoxWidth: number;

    public static minCommentBoxWidth: number = 0;

    //check: use chartsInstance instead?
    private getVisualViewport(): Viewport {
        return Visual.visualViewPort;
    }

    constructor(setting: ChartSettings, //targetSelector: string,
        commentBoxSelector: string, commentBoxDragLineSelector: string, hostElementSelector: string) {
        this.settings = setting;
        //this.target = <HTMLElement>document.querySelector(targetSelector);
        this.infoBoxContainer = <HTMLElement>document.querySelector(commentBoxSelector);
        this.dragLineElement = <HTMLElement>document.querySelector(commentBoxDragLineSelector);
        this.hostElement = <HTMLElement>document.querySelector(hostElementSelector);
        this.availableInfoBoxWidth = this.getVisualViewport().width - InfoBoxHandler.GET_AVAILABLE_CHART_CONTAINER_WIDTH(this.settings, this.getVisualViewport().width);
        this.clearCommentBox();
        this.clearCommentsBoxDragLine();
    }

    /**
     * This function will remove all the events and children from the info box target element
     * @returns new instance of cleared element
     */
    public clearCommentBox(): HTMLElement {
        this.infoBoxContainer.style.display = NONE;
        const clearTarget = <HTMLElement>this.infoBoxContainer.cloneNode(false);
        this.infoBoxContainer.parentNode.replaceChild(clearTarget, this.infoBoxContainer);
        this.infoBoxContainer = clearTarget;
        return this.infoBoxContainer;
    }

    /**
     * Removes all the events and children comment box drag line element
     * @returns new instance of cleared element
     */
    public clearCommentsBoxDragLine(): HTMLElement {
        this.dragLineElement.style.display = NONE;
        const clearTarget = <HTMLElement>this.dragLineElement.cloneNode(false);
        this.dragLineElement.parentNode.replaceChild(clearTarget, this.dragLineElement);
        this.dragLineElement = clearTarget;
        return this.dragLineElement;
    }

    private setCommentBoxBorderRadius() {
        this.infoBoxContainer.style.borderRadius = null;
        if (this.settings.commentBoxBorderRadius !== 0) {
            this.infoBoxContainer.style.borderRadius = `${this.settings.commentBoxBorderRadius}px`;
        }
    }

    private setCommentBoxShadow() {
        this.infoBoxContainer.classList.remove("shadowed");
        this.infoBoxContainer.style.margin = null;
        if (this.settings.commentBoxShadow) {
            this.infoBoxContainer.classList.add("shadowed");
            this.infoBoxContainer.style.margin = "5px";
        }
    }

    private setCommentBoxBorder() {
        this.infoBoxContainer.style.border = null;
        const borderColor = this.settings.commentBoxBorderColor;
        const borderWidth = this.settings.commentBoxBorderWidth;
        this.infoBoxContainer.style.border = `${borderWidth}${PX} ${SOLID} ${borderColor}`;
    }

    private setScrollBarToLeft(commentBoxDiv: HTMLElement) {
        this.infoBoxContainer.style.direction = null;
        commentBoxDiv.style.direction = null;
        if (this.settings.commentBoxPlacement === CommentBoxPlacement.Left) {
            this.infoBoxContainer.style.direction = "rtl";
            commentBoxDiv.style.direction = "ltr";
        }
    }

    public removeCommentBoxSettings(close?: boolean): void {
        const chartCommentSettings: HTMLElement = document.querySelector(".comment-settings");
        const commentSettingsContainer: HTMLElement = document.querySelector(".settings-interactive-container");
        if (chartCommentSettings) {
            if (commentSettingsContainer) {
                commentSettingsContainer.parentNode.removeChild(commentSettingsContainer);
            }
            if (close) {
                chartCommentSettings.classList.remove("open");
            }
        }
    }

    private drawCommentBoxSettings(): void {
        document.querySelector(".comment-box").classList.add("interactive");
        let chartCommentSettings: HTMLElement = document.querySelector(".comment-settings");
        const zebrabiChartsContainer: HTMLElement = document.querySelector(".zebrabi-charts-container");
        this.removeCommentBoxSettings();
        if (!chartCommentSettings) {
            d3.selectAll(`.visual`).append("div").classed("comment-settings", true);
            chartCommentSettings = document.querySelector(".comment-settings");
        }

        const legendTemplate = new Settings().renderSettingsContainer(".comment-settings");
        const settingsArrow: HTMLElement = document.querySelector(".settings-arrow");
        settingsArrow.style.left = "14px";
        const settingsItems: HTMLElement = document.querySelector(".settings-items");
        settingsItems.style.maxHeight = "435px";
        settingsItems.style.minHeight = "260px";
        const zebrabiChartsContainerBB = zebrabiChartsContainer.getBoundingClientRect();

        const title = <HTMLElement>settingsItems.querySelector(".title-settings");
        title.innerText = "Comment";
        const chartSettings = this.settings;

        const dropdownTitle = new DropdownElements.Dropdown("Title", [{
            value: "0",
            text: "Off"
        }, {
            value: "1",
            text: "Title",
        }, {
            value: "2",
            text: "Title, value"
        }, {
            value: "3",
            text: "Title, value, variances"
        }]);

        const [dropdownTitleSelector, dropdownTitleTemplate] = dropdownTitle.renderDropdown(settingsItems);
        dropdownTitleSelector.setValue(this.settings.commentBoxTitle.toString());

        dropdownTitleSelector.on("selectr.select", function (option) {
            chartSettings.commentBoxTitle = parseInt(option.value);
            chartSettings.persistCommentBoxSettings();
        });
        const dropdownVariance = new DropdownElements.Dropdown("Show variances", [{
            value: "0",
            text: "Absolute variance"
        }, {
            value: "1",
            text: "Relative variance",
        }, {
            value: "2",
            text: "Absolute and relative variance"
        }
        ]);

        const [dropdownVarianceSelector, dropdownVarianceTemplate] = dropdownVariance.renderDropdown(settingsItems);
        dropdownVarianceSelector.setValue(this.settings.commentBoxShowVariance.toString());

        dropdownVarianceSelector.on("selectr.select", function (option) {
            chartSettings.commentBoxShowVariance = parseInt(option.value);
            chartSettings.persistCommentBoxSettings();
        });

        const dropdownVarianceIcon = new DropdownElements.Dropdown("Variance icon", [{
            value: "0",
            text: "Circle"
        }, {
            value: "1",
            text: "Circle with arrow",
        }, {
            value: "2",
            text: "Triangle"
        }
        ]);

        const [dropdownVarianceIconSelector, dropdownVarianceIconTemplate] = dropdownVarianceIcon.renderDropdown(settingsItems);
        dropdownVarianceIconSelector.setValue(this.settings.commentBoxVarianceIcon.toString());

        dropdownVarianceIconSelector.on("selectr.select", function (option) {
            chartSettings.commentBoxVarianceIcon = parseInt(option.value);
            chartSettings.persistCommentBoxSettings();
        });
        const paddingInputNumber = new InputElements.Input("Padding", "number", this.settings.commentBoxPadding).renderInput();
        settingsItems.appendChild(paddingInputNumber);


        const gapInputNumber = new InputElements.Input("Gap between comments", "number", this.settings.commentBoxItemsMargin).renderInput();
        settingsItems.appendChild(gapInputNumber);

        paddingInputNumber.addEventListener("change", (ev) => {
            const target = ev.target as HTMLInputElement;
            chartSettings.commentBoxPadding = parseInt(target.value);
            chartSettings.persistCommentBoxSettings();
        });

        gapInputNumber.addEventListener("change", (ev) => {
            const target = ev.target as HTMLInputElement;
            chartSettings.commentBoxItemsMargin = parseInt(target.value);
            chartSettings.persistCommentBoxSettings();
        });


        const shadowSwitch: HTMLElement = new SwitchButton("Shadow", this.settings.commentBoxShadow).renderSwitch();
        settingsItems.appendChild(shadowSwitch);
        shadowSwitch.addEventListener("click", (ev) => {
            const target = ev.target as HTMLInputElement;
            chartSettings.commentBoxShadow = target.checked;
            chartSettings.persistCommentBoxSettings();
        });

        const resetButton: HTMLElement = new Button("Reset to default").renderButton();
        settingsItems.appendChild(resetButton);

        resetButton.addEventListener("click", (ev) => {
            chartSettings.commentBoxShadow = COMMENT_BOX_SHADOW_DEFAULT;
            chartSettings.commentBoxItemsMargin = COMMENT_BOX_MARGIN_DEFAULT;
            chartSettings.commentBoxPadding = COMMENT_BOX_PADDING_DEFAULT;
            chartSettings.commentBoxTitle = CommentBoxTitle.TitleValueVariance;
            chartSettings.commentBoxVarianceIcon = VarianceIcon.Triangle;
            chartSettings.commentBoxShowVariance = CommentBoxVariance.RelativeVariance;
            chartSettings.persistCommentBoxSettings();
        });

        const commentBoudingBox = document.querySelector(".zebrabi-info-container").getBoundingClientRect();
        const settingsHeight = zebrabiChartsContainerBB.bottom - commentBoudingBox.top - 20;
        settingsItems.style.height = `${settingsHeight > parseInt(settingsItems.style.maxHeight) ? settingsItems.style.maxHeight : settingsHeight}px`;
        settingsItems.style.overflowY = settingsHeight > parseInt(settingsItems.style.maxHeight) ? "hidden" : "scroll";
        chartCommentSettings.style.top = `${commentBoudingBox.top + 3}px`;
        chartCommentSettings.style.left = `${commentBoudingBox.left + 8}px`;
    }

    /**
     * This function will create the containers and populate it with the comments available in the chartDate
     * @param viewModel - view model containing the dataPoints
     * @param bottomMargin - determins the bottom margin of the comment container box
     * @param topMargin  - determins the top margin of the comment container box
     */
    // eslint-disable-next-line max-lines-per-function
    public drawCommentBox(chartData: ChartData[], bottomMargin: number, topMargin: number, isSingleMeasure: boolean = false) {
        this.isSingleMeasure = isSingleMeasure;
        this.infoBoxContainer.style.display = FLEX;
        this.infoBoxContainer.style.minWidth = InfoBoxHandler.minCommentBoxWidth + PX;
        this.infoBoxContainer.style.flexBasis = "100%";
        const flexShrinkValue = (1 / (1 - parseFloat(this.settings.commentBoxSize))) - 1;
        this.infoBoxContainer.style.flexShrink = `${flexShrinkValue}`;
        this.infoBoxContainer.style.backgroundColor = this.settings.commentBoxBackgroundColor;
        this.infoBoxContainer.addEventListener(CONTEXT_MENU, e => {
            e.preventDefault();
            e.stopPropagation();
        });

        const chartDataCommentsFull = chartData.filter(x => x.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]).length > 0);
        if (chartDataCommentsFull.length > 0) {
            const commentBoxDiv = document.createElement(DIV);
            commentBoxDiv.classList.add("comment-box");
            commentBoxDiv.style.marginTop = topMargin + PX;
            commentBoxDiv.style.marginBottom = bottomMargin + PX;
            commentBoxDiv.style.padding = `${this.settings.commentBoxPadding}${PX}`;
            commentBoxDiv.style.paddingTop = "17px";
            if (this.settings.commentBoxListHorizontal && !this.settings.isCommentBoxVertical()) {
                commentBoxDiv.style.flexDirection = ROW;
                commentBoxDiv.classList.add("horizontal");
            }
            this.dragLineElement.style.display = BLOCK;

            this.infoBoxContainer.append(commentBoxDiv);
            this.setCommentBoxBorderRadius();
            this.setScrollBarToLeft(commentBoxDiv);
            this.setCommentBoxBorder();
            this.setCommentBoxShadow();

            if (chartDataCommentsFull.length === 1) {
                this.drawSingleChartComments(chartDataCommentsFull[0], commentBoxDiv);
            }
            else {
                this.drawGroupChartsComments(chartDataCommentsFull, commentBoxDiv);

                // To avoid overlapping "Show multiples / stacked chart", "Hide chart", "Info"... icons
                if ((this.settings.commentBoxPlacement === CommentBoxPlacement.Right || this.settings.commentBoxPlacement === CommentBoxPlacement.Above) && topMargin === 0) {
                    commentBoxDiv.style.marginTop = 15 + PX;
                }
            }
            if (this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox) && this.settings.showCommentBox) {
                this.drawCommentBoxSettings();
            }
        } else {
            const noCommentsContainerDiv = document.createElement(DIV);
            noCommentsContainerDiv.style.display = FLEX;
            noCommentsContainerDiv.style.height = "calc(100% - 40" + PX + ")";
            noCommentsContainerDiv.style.padding = "20" + PX;
            noCommentsContainerDiv.style.justifyContent = CENTER;
            noCommentsContainerDiv.style.alignItems = CENTER;
            const hasCommentsField = this.settings.scenarioOptions.commentsIndices && this.settings.scenarioOptions.commentsIndices.length > 0;
            noCommentsContainerDiv.textContent = hasCommentsField ? "" : "Please add a data field to the Comments placeholder";
            this.infoBoxContainer.appendChild(noCommentsContainerDiv);
        }

        this.infoBoxContainer.onscroll = () => {
            (<any>window).commentBoxScrollTop = this.infoBoxContainer.scrollTop;
            (<any>window).commentBoxScrollLeft = this.infoBoxContainer.scrollLeft;
        };
        this.infoBoxContainer.scrollTop = (<any>window).commentBoxScrollTop;
        this.infoBoxContainer.scrollLeft = (<any>window).commentBoxScrollLeft;
    }

    public drawSingleChartComments(chartDataComments: ChartData, commentBoxDiv: HTMLDivElement) {
        const commentsGroup = document.createElement(DIV);
        const commentDataPoints = chartDataComments.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        const commentsContainer = document.createElement(DIV);
        commentsContainer.classList.add("comments-container");
        for (const [index, dataPoint] of commentDataPoints.entries()) {
            const commentElement = <HTMLElement>this.drawComment(commentsContainer, index, dataPoint, chartDataComments.axisBreak, this.settings.commentBoxTitle, this.settings.commentBoxShowVariance, this.settings.isCommentBoxVertical(), this.settings.commentBoxListHorizontal, this.settings.commentBoxItemsMargin, this.settings.invert);
            this.applyCommentStyles(commentElement, false);
        }
        commentsGroup.appendChild(commentsContainer);
        commentBoxDiv.appendChild(commentsGroup);
    }

    public drawGroupChartsComments(chartDataCommentsFull: ChartData[], commentBoxDiv: HTMLDivElement) {
        // until user makes any changes, the very first group is expanded by default
        if (!this.settings.commentBoxUserChangedExpandedGroup) {
            this.settings.commentBoxExpandedGroup = chartDataCommentsFull[0].group;
        }
        for (const chartDataComments of chartDataCommentsFull) {
            const isExpanded = this.settings.isCommentBoxGroupExpanded(chartDataComments.group);
            const commentsGroupDiv = document.createElement(DIV);
            commentsGroupDiv.classList.add("comments-group");
            const labelContainerDiv = document.createElement(DIV);
            labelContainerDiv.classList.add("comments-group-label-container");
            const groupLabel = document.createElement(P);
            groupLabel.classList.add("comments-group-label");
            groupLabel.innerText = chartDataComments.group;

            const arrow = createSvgElement(labelContainerDiv, HEADER_DROPDOWN_SETTINGS_ARROW);
            const arrowHeight = 20;
            arrow.attr(WIDTH, 20)
                .attr(HEIGHT, arrowHeight)
                .style(POSITION, RELATIVE)
                .append(POLYLINE)
                .attr(POINTS, p => {
                    const y = (arrowHeight / 2) - 2;
                    return `${3} ${y + 5}, ${8} ${y}, ${13} ${y + 5}`;
                })
                .attr(STROKE, GRAY)
                .attr(STROKE_WIDTH, 1)
                .attr(FILL_OPACITY, 0)
                .attr(VISIBILITY, VISIBLE);

            labelContainerDiv.appendChild(groupLabel);
            labelContainerDiv.appendChild(arrow.node());
            commentsGroupDiv.appendChild(labelContainerDiv);

            const commentDataPoints = chartDataComments.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
            const commentsContainer = document.createElement(DIV);
            commentsContainer.classList.add("comments-container");
            commentsContainer.classList.add("groups");

            if (isExpanded) {
                commentsContainer.classList.add("expanded");
                arrow.classed("expanded", true);
            } else {
                commentsContainer.classList.add("collapsed");
                arrow.classed("expanded", false);
            }
            for (const [index, dataPoint] of commentDataPoints.entries()) {
                const commentElement = <HTMLElement>this.drawComment(commentsContainer, index, dataPoint, chartDataComments.axisBreak, this.settings.commentBoxTitle, this.settings.commentBoxShowVariance, this.settings.isCommentBoxVertical(), this.settings.commentBoxListHorizontal, this.settings.commentBoxItemsMargin, chartDataComments.isInverted);
                this.applyCommentStyles(commentElement, false);
            }
            commentsGroupDiv.appendChild(commentsContainer);
            commentBoxDiv.appendChild(commentsGroupDiv);

            if (this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox)) {
                labelContainerDiv.classList.add("interactive");
                labelContainerDiv.onclick = () => {
                    if (isExpanded) {
                        commentsContainer.classList.remove("expanded");
                        commentsContainer.classList.add("collapsed");
                        arrow.classed("expanded", false);
                    } else {
                        // collapsing currently expanded element
                        d3.select("div.comments-container.groups.expanded").classed("collapsed", true).classed("expanded", false);
                        commentsContainer.classList.remove("collapsed");
                        commentsContainer.classList.add("expanded");
                        arrow.classed("expanded", true);
                    }
                    setTimeout(() => this.settings.persistCommentBoxExpandedGroupChange(chartDataComments.group), 400);
                };
                labelContainerDiv.onmousedown = () => {
                    labelContainerDiv.classList.add("active");
                };
                labelContainerDiv.onmouseup = () => {
                    labelContainerDiv.classList.remove("active");
                };
            }
        }
    }

    public drawDragLine(fullWidth: number, fullHeight: number) {
        this.dragLineElement.style.display = BLOCK;
        const svgResizeLine = createSvgElement(this.infoBoxContainer, "resize-line");
        let line = undefined;

        if (this.settings.isCommentBoxVertical()) {
            svgResizeLine
                .attr(HEIGHT, fullHeight)
                .attr(WIDTH, `10`);
            line = drawLine(svgResizeLine, 5, 5, 0, fullHeight, 5, null, "resize-line");
        }
        else {
            svgResizeLine
                .attr(HEIGHT, `10`)
                .attr(WIDTH, fullWidth);
            line = drawLine(svgResizeLine, 0, fullWidth, 5, 5, fullWidth, null, "resize-line");
        }
        line.attr(STROKE_WIDTH, `1`)
            .attr(STROKE, `#808080`)
            .attr(STROKE_DASHARRAY, `5,3`);

        this.dragLineElement.appendChild(svgResizeLine.node());
        this.dragLineElement.classList.remove("comment-box-resize-line-vertical", "comment-box-resize-line-horizontal", "comment-box-resize-line-hidden");
        if (!this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox)) {
            this.dragLineElement.classList.add("comment-box-resize-line-hidden");
        }
        if (this.settings.commentBoxPlacement === CommentBoxPlacement.Left || this.settings.commentBoxPlacement === CommentBoxPlacement.Right) {
            this.dragLineElement.classList.add("comment-box-resize-line-vertical");
        }
        else {
            this.dragLineElement.classList.add("comment-box-resize-line-horizontal");
        }

        let flexShrinkValue = undefined;
        let commentBoxRatio = undefined;
        const draggable = d3.drag()
            .subject(function () {
                const t = d3.select(this);
                return { x: +t.attr("x"), y: +t.attr("y") };
            }).on(DRAGSTART, () => {
                this.hostElement.style.pointerEvents = NONE;
            }).on(DRAG, (event) => {
                const x = (<any>event).sourceEvent.x;
                const y = (<any>event).sourceEvent.y;
                let tooltipValue = 0;
                switch (this.settings.commentBoxPlacement) {
                    case CommentBoxPlacement.Right:
                        flexShrinkValue = fullWidth / (fullWidth - x) - 1;
                        commentBoxRatio = x / fullWidth;
                        tooltipValue = 1 - commentBoxRatio;
                        break;
                    case CommentBoxPlacement.Left:
                        flexShrinkValue = (fullWidth / x) - 1;
                        commentBoxRatio = 1 - (x / fullWidth);
                        tooltipValue = x / fullWidth;
                        break;
                    case CommentBoxPlacement.Above:
                        flexShrinkValue = (fullHeight / y) - 1;
                        commentBoxRatio = 1 - (y / fullHeight);
                        tooltipValue = y / fullHeight;
                        break;
                    case CommentBoxPlacement.Below:
                        flexShrinkValue = fullHeight / (fullHeight - y) - 1;
                        commentBoxRatio = y / fullHeight;
                        tooltipValue = 1 - commentBoxRatio;
                        break;
                    default:
                        break;
                }
                showTooltip(this.infoBoxContainer, {
                    x: x, y: y - 20,
                }, `${(tooltipValue * 100).toFixed(0)}%`, 0, 1000);

                this.infoBoxContainer.style.flexShrink = `${flexShrinkValue}`;
                const commentContainer = d3.selectAll(".comment-box a");
                const number = d3.selectAll(".comment-box .comment-number");
                // Instead of checking this.availableWidth for width < 180 we have to check the this.infoBoxContainer.clientWidth, since its width is changes while dragging
                if ((this.settings.commentBoxListHorizontal && !this.settings.isCommentBoxVertical()) || (this.settings.isCommentBoxVertical() && this.infoBoxContainer.clientWidth < 180)) {
                    commentContainer.style(FLEX_DIRECTION, COLUMN);
                    commentContainer.style(MARGIN_RIGHT, this.settings.commentBoxItemsMargin.toString() + PX);
                    number.style(MARGIN, "10px auto");
                }
                else {
                    number.style(MARGIN, null);
                    commentContainer.style(FLEX_DIRECTION, ROW);
                    commentContainer.style(MARGIN_BOTTOM, this.settings.commentBoxItemsMargin.toString() + PX);
                }
            }).on(DRAGEND, () => {
                this.hostElement.style.pointerEvents = null;
                if (commentBoxRatio !== undefined) {
                    this.settings.persistCommentBoxSize(commentBoxRatio.toString());
                }
            });
        if (this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox)) {
            d3.select(this.dragLineElement).call(draggable);
        }
    }

    setCommentBoxPlacement(hostWrapperElement: HTMLElement) {
        let flexDirection = "";
        switch (this.settings.commentBoxPlacement) {
            case CommentBoxPlacement.Right:
                flexDirection = ROW;
                break;
            case CommentBoxPlacement.Left:
                flexDirection = ROW_REVERSE;
                break;
            case CommentBoxPlacement.Above:
                flexDirection = COLUMN_REVERSE;
                break;
            case CommentBoxPlacement.Below:
                flexDirection = COLUMN;
                break;
        }
        hostWrapperElement.style.flexDirection = flexDirection;
    }

    /**
     * This function sets the default and custom styling for comments
     * @param commentsContainer
     */
    private applyCommentStyles(commentsContainer: HTMLElement, fade: boolean) {
        const titleElements = commentsContainer.querySelectorAll("h6");
        const textElements = commentsContainer.querySelectorAll("p");

        if (this.settings.commentBoxCustomTitleStyle) {
            titleElements.forEach(title => {
                title.style.setProperty(FONT_FAMILY, this.settings.commentBoxTitleFontFamily);
                title.style.setProperty(COLOR, this.settings.commentBoxTitleFontColor);
            });

            this.drawCommentFontSize(commentsContainer, "h6", /*maxElement, 6,*/ this.settings.commentBoxTitleFontSize);
        } else { // defaults
            titleElements.forEach(title => {
                title.style.setProperty(FONT_FAMILY, this.settings.labelFontFamily);
                title.style.setProperty(COLOR, this.settings.labelFontColor);
            });

            this.drawCommentFontSize(commentsContainer, "h6", /*maxElement, 6,*/ this.settings.labelFontSize);
        }

        if (this.settings.commentBoxCustomTextStyle) {
            textElements.forEach(text => {
                text.style.setProperty(FONT_FAMILY, this.settings.commentBoxTextFontFamily);
                text.style.setProperty(COLOR, this.settings.commentBoxTextFontColor);
            });

            this.drawCommentFontSize(commentsContainer, "p", /*maxElement, 6,*/ this.settings.commentBoxTextFontSize);
        } else {
            textElements.forEach(text => {
                text.style.setProperty(FONT_FAMILY, this.settings.labelFontFamily);
                text.style.setProperty(COLOR, this.settings.labelFontColor);
            });

            this.drawCommentFontSize(commentsContainer, "p", /*maxElement, 6,*/ this.settings.labelFontSize);
        }

        if (fade) {
            commentsContainer.style.opacity = "0.3";
        }
    }

    /**
     *
     * @param dataPoint
     * dataPoint to check for category invert
     * @param invertSetting
     * invertSetting can be the global invert chart setting (when there are no groups), or the group invert, which also changes according to the global invert chart setting
     * @returns
     * comment inverted state, used to color variance icons
     */
    private isCommentInverted(dataPoint: DataPoint, invertSetting: boolean): boolean {
        let inverted = false;
        if (invertSetting) {
            inverted = true;
            if (dataPoint.isCategoryInverted) {
                inverted = false;
            }
        } else if (dataPoint.isCategoryInverted) {
            inverted = true;
        }
        return inverted;
    }

    private getRelativeDifference(dataPoint: DataPoint): number {
        if (typeof (dataPoint.relativeVariance) === "number") {
            return (dataPoint.relativeVariance * 100);
        } else {
            const absValue = dataPoint.originalValue !== null ? dataPoint.reference + dataPoint.value * (dataPoint.isNegative ? -1 : 1) : dataPoint.value;
            // in this case (waterfall) we have to multiply by -1 when category inverted
            return (dataPoint.isCategoryInverted ? -1 : 1) * viewModels.calculateRelativeDifferencePercent(absValue, dataPoint.reference);
        }
    }

    private getAbsoluteDifference(dataPoint: DataPoint): number {
        if (this.settings.chartType === ChartType.Waterfall) {
            const absVarianceValue = dataPoint.isNegative ? dataPoint.value * -1 : dataPoint.value;
            return (dataPoint.isCategoryInverted ? -1 : 1) * absVarianceValue;
        } else {
            return (viewModels.getVariance(dataPoint, false, false));
        }
    }

    private getVarianceText(dataPoint: DataPoint, commentBoxVariance: CommentBoxVariance): string {
        let absVarianceText = "";
        let relVarianceText = "";
        if (commentBoxVariance === CommentBoxVariance.RelativeVariance || commentBoxVariance === CommentBoxVariance.AbsoluteAndRelativeVariance) {
            const relDiffPercent = this.getRelativeDifference(dataPoint);
            if (dataPoint.relativeVariance) {
                relVarianceText = getRelativeVarianceLabel(this.settings, relDiffPercent, false);
            } else {
                relVarianceText = relDiffPercent === null ? "" : getRelativeVarianceLabel(this.settings, relDiffPercent, false);
            }
        }
        if (commentBoxVariance === CommentBoxVariance.AbsoluteVariance || commentBoxVariance === CommentBoxVariance.AbsoluteAndRelativeVariance) {
            const varianceLabelsFormat = getPercentageFormatOrNull(this.settings.isPercentageData, this.settings.labelPercentagePointUnit, true, true);
            const absVarianceValue = this.getAbsoluteDifference(dataPoint);
            absVarianceText = getVarianceDataLabel(absVarianceValue, this.settings.decimalPlaces, this.settings.displayUnits, this.settings.locale,
                this.settings.showNegativeValuesInParenthesis(), this.settings.showPercentageInLabel, false, false, varianceLabelsFormat, false);
        }

        if (commentBoxVariance === CommentBoxVariance.AbsoluteVariance) {
            return absVarianceText;
        } else if (commentBoxVariance === CommentBoxVariance.RelativeVariance) {
            return relVarianceText;
        } else {
            return `${absVarianceText} | ${relVarianceText}`;
        }
    }

    private getValue(dataPoint: DataPoint, axisBreak: number): number {
        const isSingleMeasureWaterfall = this.isSingleMeasure && this.settings.chartType === ChartType.Waterfall;
        const isSingleMeasureWaterfallLabelSpecific = isSingleMeasureWaterfall && dataPoint.isNegative !== dataPoint.isCategoryInverted;

        const axisBreakAddition = this.settings.hasAxisBreak
            && (this.settings.chartType !== ChartType.Waterfall || (isSingleMeasureWaterfall && dataPoint.isCategoryResult && !dataPoint.isCategoryFloatingResult))
            ? axisBreak
            : 0;

        let value = dataPoint.originalValue !== null && dataPoint.originalValue !== undefined
            ? dataPoint.originalValue
            : dataPoint.value + axisBreakAddition;

        // Handle empty AC in case of existing PL and FC
        if (dataPoint.value === null && dataPoint.secondSegmentValue !== null) {
            value = dataPoint.secondSegmentValue;
        }

        if (isSingleMeasureWaterfallLabelSpecific) {
            value = -1 * value;
        }

        return value;
    }

    private getValueText(value: number, dataPoint: DataPoint, isSingleMeasureWaterfall: boolean): string {
        const labelsFormat = getPercentageFormatOrNull(this.settings.isPercentageData, this.settings.labelPercentagePointUnit, false, true);
        const hideUnits = this.settings.shouldHideDataLabelUnits();

        return getFormattedDataLabel(value, this.settings.decimalPlaces, this.settings.displayUnits, this.settings.locale,
            this.settings.showNegativeValuesInParenthesis(), this.settings.showPercentageInLabel,
            isSingleMeasureWaterfall ? dataPoint.isVariance : false,
            false, false, { percentageFormat: labelsFormat, pbiFormat: undefined }, hideUnits);
    }

    private getTitle(dataPoint: DataPoint, axisBreak: number, commentBoxTitle: CommentBoxTitle, commentBoxVariance: CommentBoxVariance, invertSetting: boolean): HTMLElement {
        const varianceText = this.isSingleMeasure ? "" : this.getVarianceText(dataPoint, commentBoxVariance);
        const isInverted = this.isCommentInverted(dataPoint, invertSetting);
        const isSingleMeasureWaterfall = this.isSingleMeasure && this.settings.chartType === ChartType.Waterfall;
        const value = this.getValue(dataPoint, axisBreak);
        const valueText = this.getValueText(value, dataPoint, isSingleMeasureWaterfall);
        const relDiffPercent = this.getRelativeDifference(dataPoint);

        // different handling of coloring is due to the lack of dataPoint.reference for the single measure charts, which set relDiffPercent to 0
        // we should consider replacing relDiffPercent with "value" for multiple measure charts
        const varianceColor = isSingleMeasureWaterfall
            ? getVarianceColor(isInverted, value, this.settings.colorScheme)
            : getVarianceColor(isInverted, relDiffPercent, this.settings.colorScheme);

        const titleElement = document.createElement("h6");
        const varianceValue = document.createElement("span");
        const titleCategory = document.createElement("span");

        titleCategory.innerText = dataPoint.category;
        titleElement.append(titleCategory);

        if (commentBoxTitle === CommentBoxTitle.TitleValue || commentBoxTitle === CommentBoxTitle.TitleValueVariance) {
            if (valueText) {
                titleCategory.innerText += " " + valueText;
            }
        }

        if (commentBoxTitle === CommentBoxTitle.TitleValueVariance) {
            varianceValue.innerText = varianceText;

            let varianceIcon = null;
            if (!this.isSingleMeasure || isSingleMeasureWaterfall) {
                varianceIcon = createSvgElement(titleElement, "variance-icon")
                    .attr(WIDTH, 20)
                    .attr(HEIGHT, 20);
                drawVarianceIcon(varianceIcon, this.settings.commentBoxVarianceIcon, varianceColor,
                    (isSingleMeasureWaterfall ? this.getAbsoluteDifference(dataPoint) : relDiffPercent) < 0);
            }

            if (this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox)) {
                if (varianceIcon) {
                    varianceIcon.classed("interactive", true);
                    varianceIcon.on(CLICK, () => {
                        let commentBoxVarianceIcon = this.settings.commentBoxVarianceIcon;
                        commentBoxVarianceIcon = commentBoxVarianceIcon === 2 ? 0 : commentBoxVarianceIcon + 1;
                        this.settings.persistCommentBoxVarianceIcon(commentBoxVarianceIcon);
                    });
                    varianceIcon.on(MOUSEOVER, () => {
                        const bb = getBoundingBoxHtml(<HTMLElement>(<unknown>varianceIcon.node()));
                        showTooltip(this.infoBoxContainer, {
                            x: bb.x - 10,
                            y: Math.min(bb.top + 20, this.infoBoxContainer.offsetTop + this.infoBoxContainer.clientHeight - 40)
                        }, `Click to change variance icon`, 250, 1000);
                    });
                }

                varianceValue.classList.add("interactive");
                varianceValue.onclick = () => {
                    commentBoxVariance = commentBoxVariance === 2 ? 0 : commentBoxVariance + 1;
                    this.settings.persistCommentBoxVariance(commentBoxVariance);
                };
                varianceValue.onmouseover = () => {
                    const bb = getBoundingBoxHtml(<HTMLElement>varianceValue);
                    showTooltip(this.infoBoxContainer, {
                        x: bb.x - 10,
                        y: Math.min(bb.top + 20, this.infoBoxContainer.offsetTop + this.infoBoxContainer.clientHeight - 40)
                    }, `Click to change variance`, 250, 1000);
                };
            }
            varianceValue.classList.add("variance-value");
            titleElement.append(varianceValue);
        }

        return titleElement;
    }

    /**
     * This function will draw a single comment
     * @param container - target Element the comment will be rendered to
     * @param index - display number of the comment
     * @param dataPoint - contains comment data
     * @param axisBreak - in case the chart has a break point this will contain the value of the visually hidden value
     * @returns comment container HTMLAnchorElement
     */
    private drawComment(container: Element, index: number, dataPoint: DataPoint, axisBreak: number = 0, commentBoxTitle: CommentBoxTitle, commentBoxVariance: CommentBoxVariance, commentBoxVertical: boolean, commentBoxListHorizontal: boolean, commentBoxItemsMargin: number, invertSetting: boolean) {
        const commentContainer = document.createElement("a");
        commentContainer.classList.add("comment-container");
        const number = document.createElement(SPAN);
        const textContainer = document.createElement(DIV);
        const text = document.createElement(P);

        if (commentBoxTitle !== CommentBoxTitle.Off) {
            const titleElementContainer = document.createElement(DIV);
            titleElementContainer.classList.add("comment-title");
            titleElementContainer.style.fontWeight = BOLD;
            const titleElement = this.getTitle(dataPoint, axisBreak, commentBoxTitle, commentBoxVariance, invertSetting);
            titleElementContainer.append(titleElement);
            textContainer.append(titleElementContainer);
        }

        textContainer.classList.add("comment-text-container");
        if (commentBoxTitle === CommentBoxTitle.Off) {
            textContainer.classList.add("no-title");
        }
        number.classList.add("comment-number");
        number.style.color = this.settings.colorScheme.highlightColor;
        number.style.borderColor = this.settings.colorScheme.highlightColor;
        number.style.fontFamily = this.settings.commentBoxTextFontFamily;
        let numberValue: number;
        let commentValue: string;

        if (dataPoint.commentsMeasuresValues.length > 1) {
            if (typeof (dataPoint.commentsMeasuresValues[0]) === "number") {
                numberValue = dataPoint.commentsMeasuresValues[0];
            } else {
                numberValue = index + 1;
            }
            commentValue = <string>dataPoint.commentsMeasuresValues[1];
        } else {
            numberValue = index + 1;
            commentValue = <string>dataPoint.commentsMeasuresValues[0];
        }

        number.innerText = String(numberValue);
        if (!licensing.proFeaturesUnlocked() && licensing.getCurrentUser() && numberValue > FREE_MODE_COMMENT_LIMIT) {
            text.innerText = "Upgrade your Zebra BI for Office to display more than 3 comments here.";
        } else {
            text.innerText = commentValue;
        }

        if (commentBoxListHorizontal) {
            commentContainer.style.minWidth = "20vw";
        }

        if ((commentBoxListHorizontal && !commentBoxVertical) || (commentBoxVertical && this.availableInfoBoxWidth < 180)) {
            commentContainer.style.flexDirection = "column";
            commentContainer.style.marginRight = `${commentBoxItemsMargin}${PX}`;
            number.style.margin = "10px auto";
        }
        else {
            commentContainer.style.marginBottom = `${commentBoxItemsMargin}${PX}`;
        }
        textContainer.append(text);
        commentContainer.append(number);
        commentContainer.append(textContainer);
        container.append(commentContainer);
        return commentContainer;
    }

    /**
     * This function will determine the font size of the element specified - currently most of the functionality has been put on hold
     * @param container container that holds the children the font size should be applied to
     * @param selector selector determining the childred that will be affected by the font size
     * @param maxSize upper limit of the font size - currently the acutal font size due to the autosize function being set on hold
     */
    private drawCommentFontSize(container: Element, selector: string, /*maxElement: Element, minSize: number,*/ maxSize: number) {
        /* const sample = <HTMLElement>maxElement.querySelector(selector);
         sample.style.whiteSpace = "nowrap";
         const containerSize = sample.getBoundingClientRect();

         const textWidth = containerSize.width;
         const availableSpace = sample.parentElement.parentElement.getBoundingClientRect().width;
         const measuredFontSize = parseFloat(window.getComputedStyle(sample, null).getPropertyValue('font-size'));
         sample.style.whiteSpace = "normal";
         const currentFontSize = maxSize; //Math.min(Math.max(availableSpace / textWidth * measuredFontSize, minSize), maxSize);*/

        const elements = container.querySelectorAll(selector);
        elements.forEach(element => {
            const htmlElement = (<HTMLElement>element);
            htmlElement.style.fontSize = maxSize + FONT_SIZE_UNIT;
        });
    }

    /**
     * This functon calculates the break point for longer texts. It accepts the text to be wrapped, the container width and the individual line height
     * Used for SVG text wrapping
     * @param text - the text that is in need to be wrapped
     * @param width - available width for a text row
     * @param lineHeight - the height of the text row
     * */
    private textWrap(text: any, width: number, lineHeight: number) {
        text.each(function () {
            const text = d3.select(this);
            const words = text.text().split(/\s+/).reverse();
            let word = "";
            let line = [];
            let lineNumber = 0;
            const y = text.attr("y");
            let tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y);
            // eslint-disable-next-line no-cond-assign
            while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight).text(word);
                }
            }
        });
    }

    /**
     * Calculate the awailable width the chart has after the comment box is turned on
     * @param settings - settings to see if comment box is turend of and if any changes to the width are necessary
     * @param width - awaiable width in total
     * @returns number - width of awailable space for the chart
     */
    public static GET_AVAILABLE_CHART_CONTAINER_WIDTH(settings: ChartSettings, width: number): number {
        if (settings.commentBoxPlacement === CommentBoxPlacement.Left || settings.commentBoxPlacement === CommentBoxPlacement.Right) {
            width = width * (Number.parseFloat(settings.commentBoxSize));
        }
        return width;
    }

    public static GET_AVAILABLE_CHART_CONTAINER_HEIGHT(settings: ChartSettings, height: number): number {
        if (settings.commentBoxPlacement === CommentBoxPlacement.Above || settings.commentBoxPlacement === CommentBoxPlacement.Below) {
            height = height * (Number.parseFloat(settings.commentBoxSize));
            height -= 10; // to compensate for the dragLine height
        }
        return height;
    }
}
