// Chart layout options
export const ABSOLUTE = "Absolute";
export const RELATIVE = "Relative";
export const INTEGRATED = "Integrated";
export const RESPONSIVE = "Responsive";
export const WATERFALL = "Waterfall";
export const ACTUAL = "Actual";
export const ACTUAL_ABSOLUTE = "Actual / Absolute";
export const ACTUAL_RELATIVE = "Actual / Relative";
export const ABSOLUTE_RELATIVE = "Absolute / Relative";

// multiples layout options
export const AUTO_LAYOUT = "Auto";
export const ROWS_LAYOUT = "Rows";
export const LARGEST_FIRST_LAYOUT = "LargestFirst";

// Data properties
export const VALUE = "value";
export const REFERENCE = "reference";
export const SECOND_SEGMENT_VALUE = "secondSegmentValue";
export const SECOND_REFERENCE = "secondReference";
export const START_POSITION = "startPosition";
export const SECOND_ACTUAL_VALUE = "secondActualValue";

export const LINE_BREAK_SEPARATOR = "\n";

export const INFO_BOX_CONTAINER = "zebrabi-info-container";
