import "./visual.less";
import "./style/ui.less";

// images references in the manifest
import "./assets/icon-32.png";
import "./assets/icon-64.png";
import { analyticsHandler, ignoredEditEvents } from "@zebrabi/analytics-tracking/AnaliticsHandler";
import { ActionType } from "@zebrabi/licensing/ActionType";
import { OwnerUserInfo, CurrentUserInfo } from "@zebrabi/licensing/components/UserInfo";
import ActionObserver from "@zebrabi/licensing/observers/ActionObserver";
import { licensing } from "@zebrabi/licensing/Licensing";
import * as process from "process";
import { DataHelper } from "./dataHelper";
import { ViewModel, Viewport } from "./interfaces";
import { CHART_SETTINGS_NAME, ChartSettings } from "./settings/chartSettings";
import * as viewModelFactory from "./viewModel/viewModelFactory";
import * as viewModelHelpers from "./viewModel/viewModelHelperFunctions";
import { delay } from "./helpers";

import { valueFormatter } from "powerbi-visuals-utils-formattingutils";
import * as d3 from "./d3";
import { ChartType, ShowTopNChartsOptions, Sort } from "./enums";
import {
    BLOCK, COMMENT_BOX_RESIZE_LINE, DISPLAY, NONE, ZEBRABI_CHART_SVG_CONTAINER, CLICK, DEFAULT_FONT_SIZE, FONT_SIZE_UNIT, MOUSELEAVE, MOUSEENTER, DIV
} from "./library/constants";
import { CONTEXT_MENU, CONTEXT_MENU_DIV } from "@zebrabi/constants";
import { INFO_BOX_CONTAINER } from "./consts";
import InfoBoxHandler from "./ui/InfoBoxHandler";
import {
    subscribeToXSpreadSheetData,
    subscribeToTableDataEditorData,
    initTableDataEditorStore,
    VisualType,
} from "@zebrabi/table-data-editor";
import { DataView, getOfficeStorageTableData, setOfficeStorageTableData, prepareTableData } from "@zebrabi/table-data";
import { initChartChooserStore, mountChartChooser } from "@zebrabi/chart-chooser";
import GlobalToolbar from "@zebrabi/global-toolbar/dist/lib/components/GlobalToolbar";
import GlobalToolbarOld from "@zebrabi/global-toolbar-old/components/GlobalToolbar";
import AboutSwitcher from "./toolbar/components/AboutSwitcher";
import DataTableEditorSwitcher from "./toolbar/components/DataTableEditorSwitcher";
import TableDataEditorObserver from "./toolbar/observers/TableDataEditorObserver";
import StackedChartSwitcher from "./toolbar/components/StackedChartSwitcher";
import StackedChartObserver from "./toolbar/observers/StackedChartObserver";
import AccountSwitcher from "./toolbar/components/AccountSwitcher";
import { openURL } from "@zebrabi/licensing/helpers";
import { signInService } from "@zebrabi/licensing/components/signin/MSFTSignIn";
import { initExcelDataLinkingStore } from "../../excel-data-linking/mount";
import { extractExistingAnalyticsId, initializeAnalyticsOptOut, initializeAnalyticsReporting } from "../../analytics-tracking/helpers";
import axios from "axios";
import { flagHandler } from "@zebrabi/zebrabi-core";
import { sortAscending, sortDescending } from "./helpers";
import { OrganizationStyleData, OrganizationStyleSettings, getAvailableOrganizationStyles, getOrganizationStyleById } from "@zebrabi/data-helpers/organizationStyles";
import ChartSettingsSwitcher from "./toolbar/components/ChartSettingsSwitcher";
import ChartSettingsSwitcherObserver from "./toolbar/observers/ChartSettingsSwitcherObserver";
import AboutSwitcherOld from "./toolbar/components/AboutSwitcherOld";
import FieldsChartsSwitcher from "./toolbar/components/FieldsChartsSwitcher";
import FieldsChartsSwitcherObserver from "./toolbar/observers/FieldsChartsSwitcherObserver";
import SettingsChartsSwitcher from "./toolbar/components/SettingsChartsSwitcher";
import SettingsChartsSwitcherObserver from "./toolbar/observers/SettingsChartsSwitcherObserver";

import { Charts } from "@zebrabi/charts/dist/bundle/main";
//import { IChartSettings } from "./settings/IChartSettings";
import { ViewMode } from "@zebrabi/table-data";
import initializeInteractionHandlers from "./initializeInteractionHandlers";
import { OfficeSelectionService as SelectionService } from "./SelectionService";

import fsm from "../../../flagsmith.json";
import { getOfficeSettings } from "@zebrabi/office-settings";

// Initialization
Office.onReady(async (info) => {
    if (process.env.DEBUG_LOG === "true") {
        console.debug("Office ready info: ", info);
    }

    const isEdgeOrIE = window.navigator.userAgent.indexOf("Trident") !== -1 || window.navigator.userAgent.indexOf("Edge") !== -1;
    if (isEdgeOrIE) {
        const loadingElementMsg = document.getElementById("main-message");
        if (loadingElementMsg) {
            loadingElementMsg.textContent = "This Office environment is not supported. Please upgrade your Microsoft Office to newer version.";
        }
        return;
    }

    if (info.host === Office.HostType.Excel || info.host === Office.HostType.PowerPoint) {
        const settings: ChartSettings = getOfficeSettings(CHART_SETTINGS_NAME);
        const visual = Visual.getInstance(settings);

        /** Settings are null when we are on ChartChooserScreen, Only after choosing the chart should we show the data editor **/
        if (settings === null) {
            Visual.isFirstLoad = true;
        }

        visual.update(settings).then(() => {
            flagHandler.init({
                api: process.env.FLAGSMITH_HOST,
                defaultFlags: fsm.flags,
                environment: process.env.MODE === "development" ? process.env.FLAGSMITH_DEV : process.env.FLAGSMITH_PROD,
                preventFetch: process.env.FLAGSMITH_LOCAL_ONLY === "true",
                identity: process.env.FLAGSMITH_USER ?? null,
            }).then(() => {
                // currently not needeed, no active flags
                // const visual = Visual.getInstance(settings);
                // visual.update(settings);
            });
        });
    }
});

export interface IVisual {
    update(updateSettings?: ChartSettings): Promise<void>;
    getDataView(): DataView;
    getSettings(): ChartSettings;
}

export class Visual implements IVisual {
    private chartsInstance: Charts;

    getSettings(): ChartSettings {
        return Visual.settings;
    }

    getDataView(): DataView {
        return Visual.dataView;
    }

    private addInBodyEl: HTMLBodyElement;
    private chartsContainer: HTMLDivElement;
    public static element: HTMLElement; //todo: remove this?
    public welcomeHeader: HTMLElement;

    private static instance: Visual;

    public static getInstance(settings?: ChartSettings) {
        if (!Visual.instance) {
            Visual.instance = new Visual(settings);
        }
        return Visual.instance;
    }

    public dataHelper: DataHelper;
    public static dataView: DataView;

    public static settings: ChartSettings;  //todo: remove these static properties, use ones from instance
    static viewModel: ViewModel;

    private viewModel: ViewModel;

    public showCompanyStyle = false;

    static formatterMap: Map<string, valueFormatter.IValueFormatter>;

    private isLicenseFetchingDone = false;

    static tableDataEditorSwitcher: DataTableEditorSwitcher;
    static isFirstLoad: boolean = false;

    static visualViewPort: Viewport;
    private globalToolbar: GlobalToolbar | GlobalToolbarOld;
    static isFirstTimeInsert: boolean;

    private currentOrganizationStyle: OrganizationStyleData;
    public availableOrganizationStyles: OrganizationStyleData[];

    public getOrganizationStyleSettings(): OrganizationStyleData {
        return this.currentOrganizationStyle;
    }

    public removeOrganizationStyle() {
        this.currentOrganizationStyle = null;
    }

    public async getOrganizationStyle(organizationId: string): Promise<OrganizationStyleSettings> {
        try {
            const getOrganizationStyleUrl = process.env.LICENSING_SERVER_URL + `/add-in/organization-styles/${organizationId}/default`;
            const response = await axios.get(getOrganizationStyleUrl);
            const styleSettings = response.data.styleJSON;

            return styleSettings;
        } catch (e) {
            console.error(e);
        }

        return null;
    }

    public applyDefaultOrganizationStyle(styleId: number) {
        if (Visual.isFirstLoad) {
            this.applyOrganizationStyleById(styleId);
        }
    }

    public applyOrganizationStyleById(styleId: number) {
        getOrganizationStyleById(styleId).then(orgSettings => {
            if (!orgSettings) {
                return;
            }
            this.currentOrganizationStyle = orgSettings;
            if (Visual.settings) {
                Visual.settings.setOrganizationStyleSettings(this.currentOrganizationStyle);
                this.update(Visual.settings);
            }
        });
    }

    public loadOrganizationStyles(organizationId: string, userId: string) {
        if (!organizationId) {
            return;
        }
        getAvailableOrganizationStyles(organizationId, userId).then((availableStyles) => {
            this.availableOrganizationStyles = availableStyles;
            availableStyles?.forEach(styleData => {
                if (styleData.isDefault) {
                    this.applyDefaultOrganizationStyle(styleData.id);
                }
            });
        });
    }

    constructor(settings?: ChartSettings) {

        Visual.isFirstTimeInsert = !Office.context.document.settings.get(CHART_SETTINGS_NAME);
        this.dataHelper = new DataHelper(this, settings);

        this.handleSignIn();

        initChartChooserStore();

        if (Office.context.host === Office.HostType.PowerPoint) {
            mountChartChooser();
        }
        initTableDataEditorStore({ visualType: VisualType.CHARTS });
        if (Office.context.host === Office.HostType.PowerPoint) {
            initExcelDataLinkingStore();
        }
        // Read initial table data from Office storage
        const tableData = getOfficeStorageTableData();
        Visual.dataView = prepareTableData(tableData);

        // Local storage event listener
        subscribeToXSpreadSheetData(async (tableData: DataView) => {
            if (Visual.isFirstLoad) {
                if (Office.context.host === Office.HostType.PowerPoint && Office.context.document.mode !== Office.DocumentMode.ReadOnly) {
                    setTimeout(() => Visual.tableDataEditorSwitcher.open(), 100);
                }
            }

            const dataView = prepareTableData(tableData);

            setOfficeStorageTableData(dataView);
            Visual.dataView = dataView;
            if (Office.context.host === Office.HostType.Excel) {
                await this.dataHelper.writeDataViewToWorksheet();
            }
            this.update(Visual.settings);
        });

        subscribeToTableDataEditorData(() => {
            this.update(Visual.settings);
        });

        this.addInBodyEl = document.getElementsByTagName("body")[0];
        Visual.element = this.addInBodyEl;
        this.welcomeHeader = document.getElementById("welcome-header");
        this.welcomeHeader.focus();		// focusing this element enables inset from selected range in Excel

        Visual.visualViewPort = this.getViewport(this.addInBodyEl);

        // chartsInstance container
        this.chartsContainer = document.createElement("div",);
        this.chartsContainer.className = "office-charts-container";
        this.addInBodyEl.appendChild(this.chartsContainer);

        this.chartsInstance = new Charts(this.chartsContainer);

        this.chartsInstance.setSelectionProvider(new SelectionService(Office.context.host === Office.HostType.Excel));
        // this.chartsInstance.setTooltipProvider(...);

        Visual.formatterMap = new Map<string, valueFormatter.IValueFormatter>();

        // Add resize handler
        window.onresize = () => {
            Visual.visualViewPort = this.getViewport(this.addInBodyEl);
            this.constructViewModelAndVisualUpdate(Visual.settings);
        };

        d3.select(document.body).on(MOUSELEAVE, () => {
            // check if ppt spreadsheed is open, should not be hidden on mouse leave
            const shouldKeepOpenToolbar = Office.context.host === Office.HostType.PowerPoint && Visual.tableDataEditorSwitcher &&
                document.querySelector("." + Visual.tableDataEditorSwitcher.getStyleClassName()).classList.contains("active");
            if (!shouldKeepOpenToolbar) {
                this.globalToolbar?.hide();
            }
        });

        d3.select(document.body).on(MOUSEENTER, () => {
            this.globalToolbar?.show();
        });

        this.initToolbar(settings);

        if (Office.context.host === Office.HostType.Excel && !settings) {
            this.setFocus();
        }

        this.setUpCSSVariables();
        this.addContextMenuDiv();
    }

    // todo: move this context-menu element from apps into charts package
    private addContextMenuDiv() {
        const context = document.createElement(DIV);
        context.className = `${CONTEXT_MENU_DIV} ${CONTEXT_MENU_DIV}-theme`;
        context.addEventListener(CONTEXT_MENU, e => e.preventDefault());
        this.chartsContainer.appendChild(context);
        context.onmouseleave = () => {
            const contextMenuDisplay = d3.select("." + CONTEXT_MENU_DIV).style(DISPLAY);
            if (contextMenuDisplay === BLOCK) {
                d3.select("." + CONTEXT_MENU_DIV).style(DISPLAY, NONE);
            }
        };
    }

    private setUpCSSVariables(): void {
        document.documentElement.style
            .setProperty('--default-font-size', `${DEFAULT_FONT_SIZE}${FONT_SIZE_UNIT}`);
    }

    private getViewport(el: HTMLElement): Viewport {
        return {
            width: el.clientWidth || el.parentElement?.clientWidth,
            height: el.clientHeight || el.parentElement?.clientHeight
        };
    }

    private setFocus() {
        // her we set focus to some element to deselect the add-in and enable "insert from range" functionality in Excel
        const el = document.createElement("input");
        this.addInBodyEl.appendChild(el);
        this.addInBodyEl.blur();
        el.focus();
        el.hidden = true;
    }

    // reads Excel data and updates the add-in
    public async update(updateSettings?: ChartSettings): Promise<void> {
        if (process.env.DEBUG_LOG === "true") {
            console.debug("updating...", updateSettings, performance.now());
        }

        const dataView = await this.dataHelper.getDataView();
        Visual.dataView = dataView;

        this.constructViewModelAndVisualUpdate(updateSettings);
    }

    public constructViewModelAndVisualUpdate(updateSettings?: ChartSettings): void {
        const locale = Office.context.contentLanguage || "en-US";

        if (Visual.dataView && !Visual.dataView.errorMessage) {
            this.viewModel = viewModelFactory.getViewModel(Visual.dataView, this.showCompanyStyle, locale, updateSettings);
            this.viewModel.settings = new Proxy(this.viewModel.settings, {
                set: (target: ChartSettings, p: string, value: any): boolean => {
                    let oldValue = target[p];
                    if (oldValue !== value) {
                        target[p] = value;
                        if (p === "chartType") {
                            analyticsHandler.report(`edit/${p}/${ChartType[value]}`);
                        } else if (!ignoredEditEvents.includes(p)) {
                            analyticsHandler.report(`edit/${p}`);
                        }
                    }
                    return true;
                }
            });
            Visual.viewModel = this.viewModel;
            Visual.settings = this.viewModel.settings;
        }

        this.chartsUpdate();
    }

    private chartsUpdate() {
        if (!this.chartsInstance) {
            this.chartsInstance = new Charts(this.chartsContainer);
        }

        if (Visual.dataView.errorMessage) {
            this.showErrorMessage(Visual.dataView.errorMessage);
            return;
        }

        this.welcomeHeader.style.display = "none";
        if (this.viewModel?.chartData?.length > 0) {    // ??
            const viewModel = this.viewModel;
            const settings = Visual.settings;

            const chartsSettings = settings;
            //const chartSettings: IChartSettings = settings as IChartSettings;
            this.chartsInstance.setSettings(chartsSettings, ViewMode.Edit, this.getLocale(), settings.scenarioOptions);

            //todo? check: ChartsVisual.settings =/merge this.chartsInstance.setSettings.getSettings()

            this.viewModel.isMultiples = this.viewModel.chartData.length > 1;
            d3.select("#StackedChartSwitcher").style(DISPLAY, this.viewModel.isMultiples ? BLOCK : NONE);

            if (viewModel.isMultiples && !viewModel.is2dMultiples && (!settings.stackedChart && settings.showTopNChartsOptions !== ShowTopNChartsOptions.Off ||
                settings.stackedChart && settings.showTopNStackedOptions !== ShowTopNChartsOptions.Off)) {
                this.viewModel = viewModelHelpers.replaceSmallerChartsWithOthers(this.viewModel, this.viewModel.settings);
            }

            if (!settings.shouldPlotStackedChart(viewModel.isMultiples) && settings.hasAxisBreak && (settings.chartType === ChartType.Waterfall || settings.chartType === ChartType.Area || settings.chartType === ChartType.Line)) {
                viewModelHelpers.adjustViewModelForAxisBreak(viewModel, settings.axisBreakPercent);
            }

            if (settings.isChartDataToBeSorted(viewModel)) {
                const chartSortType = settings.stackedChart ? settings.stackedChartSort : settings.multiplesSort;
                if (chartSortType === Sort.Ascending) {
                    sortAscending(viewModel.chartData, item => item.max - item.min);
                } else if (chartSortType === Sort.Descending) {
                    sortDescending(viewModel.chartData, item => item.max - item.min);
                }
            }

            //? this.chartsInstance.ui.setChartsContainerElementWidthHeight(options.viewport.width, options.viewport.height);
            //? this.chartsInstance.ui.setLicensingHandlers(initializeLicensingHandlers());
            const interactionHandlers = initializeInteractionHandlers(this);
            this.chartsInstance.setInteractionHandlers(interactionHandlers);

            this.chartsInstance.update(
                {
                    allowInteractiveCommentBox: true,
                    allowMenus: true,
                    clientEventInteractivityAllowed: true,
                    slideSwitchingAllowed: true,
                    pbiFormatEnabled: false,
                },
                Visual.viewModel
            );

            const infoBox = new InfoBoxHandler(settings, `.${INFO_BOX_CONTAINER}`, `.${COMMENT_BOX_RESIZE_LINE}`, `.${ZEBRABI_CHART_SVG_CONTAINER}`);
            infoBox.clearCommentBox();
            if (settings.showCommentBox) {
                infoBox.drawCommentBox(this.viewModel.chartData, 0, 0, viewModel.isSingleSeriesViewModel);
                const commentBoxResizeLine = infoBox.clearCommentsBoxDragLine();
                infoBox.setCommentBoxPlacement(commentBoxResizeLine.parentElement);
                infoBox.drawDragLine(Visual.visualViewPort.width, Visual.visualViewPort.height);
            }

            this.globalToolbar?.render(settings.stackedChart);
            this.checkWatermark();
            // add current settings to persist queue...
            Visual.settings?.persist();
        }

        // not clear yet what to do with "advert" chart type, possibly to remove
        // if (this.viewModel?.settings?.chartType === ChartType.Advert) {
        //     this.plotAdvertSlide();
        //     this.sliderHandler.refreshUIelements(ChartsVisual.visualViewPort.width, this.addInBodyEl, ChartsVisual.settings, ChartsVisual.visualViewPort.height);
        //     this.checkWatermark();
        // }
    }

    private showErrorMessage(errorMsg: string) {
        this.welcomeHeader.style.display = null;
        d3.select(this.welcomeHeader).classed("error-message", true);
        d3.select(this.welcomeHeader).html(errorMsg);

        const kbLink = <HTMLAnchorElement>this.welcomeHeader.querySelector("#error-msg-kb-url");
        if (kbLink) {
            kbLink.addEventListener(CLICK, (e) => {
                e.preventDefault();
                e.stopPropagation();
                openURL(kbLink.href);
            });
        }

        const templateLink = <HTMLAnchorElement>this.welcomeHeader.querySelector("#error-msg-template-url");
        if (templateLink) {
            templateLink.addEventListener(CLICK, (e) => {
                e.preventDefault();
                e.stopPropagation();
                openURL(templateLink.href);
            });
        }
    }

    /*
    private async plotAdvertSlide() {
        const currentUserInfo: CurrentUserInfo = licensing.getCurrentUser();
        const isTrialUser = currentUserInfo?.isTrial();
        let content = await fetch("brandslides_charts.html");
        let text = await content.text();
        let el = document.createElement(DIV);
        el.style.display = FLEX;
        el.style.justifyContent = CENTER;
        el.style.alignItems = CENTER;
        el.style.width = "100%";
        el.style.height = "100%";
        el.innerHTML = text;

        let buyUrl = (isTrialUser ? UNLOCK_ALL_FEATURES_URL : START_TRIAL_URL)
            .replace("$host", Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint)
            .replace("$visual", process.env.ZBI_VISUAL);
        this.main.innerHTML = "";
        this.main.appendChild(el);

        const upgradeLink = <HTMLAnchorElement>el.querySelector("#upgrade-link");
        upgradeLink.text = isTrialUser ? "Upgrade" : "Start Free Trial";
        upgradeLink.addEventListener(CLICK, () => openURL(buyUrl));
    }*/

    private initToolbar(settings: ChartSettings) {
        this.initGlobalToolbarOld(settings);
        // the "new" toolbar is not yet fully implemented, so we use the old one for now
        // if (flagHandler.has("global-toolbar")) {
        //     this.initGlobalToolbar(settings);
        // } else {
        //     this.initGlobalToolbarOld(settings);
        // }
    }

    public initGlobalToolbar(settings?: ChartSettings) {
        const globalToolbar = new GlobalToolbar(this.addInBodyEl);
        globalToolbar.add(new AboutSwitcher());

        const chartSettingsSwitcher = new ChartSettingsSwitcher();

        // Observer type determines what object gets the returned data if you set it in the switcher notify call
        const visualObserver: ChartSettingsSwitcherObserver = new class extends ChartSettingsSwitcherObserver { };
        visualObserver.update = (formData: Map<string, any>) => {
            for (let [key, value] of formData) {
                if (isNaN(Number(value))) {
                    continue;
                }

                formData.set(key, Number(value));
            }

            this.updateSettingsFromForm(formData);
            this.constructViewModelAndVisualUpdate(Visual.settings);
        };

        chartSettingsSwitcher.attach(visualObserver); // start listening for changes
        globalToolbar.add(chartSettingsSwitcher);
        globalToolbar.render();

        this.globalToolbar = globalToolbar;
    }

    public initGlobalToolbarOld(settings?: ChartSettings) {
        const globalToolbar = new GlobalToolbarOld(this.addInBodyEl);

        const fieldsSwitcher = new FieldsChartsSwitcher();
        const fieldsSwitcherObserver = new class extends FieldsChartsSwitcherObserver { };
        fieldsSwitcherObserver.update = (formData: Map<string, any>) => {
            this.updateSettingsFromForm(formData);
            this.constructViewModelAndVisualUpdate(Visual.settings);
        };
        fieldsSwitcher.attach(fieldsSwitcherObserver);

        const settingsSwitcher = new SettingsChartsSwitcher();
        const settingsSwitcherObserver = new class extends SettingsChartsSwitcherObserver { };
        settingsSwitcherObserver.update = (formData: Map<string, any>) => {
            this.updateSettingsFromForm(formData);
            this.constructViewModelAndVisualUpdate(Visual.settings);
        };
        settingsSwitcher.attach(settingsSwitcherObserver);

        if (Office.context.host === Office.HostType.PowerPoint) {
            // Observer type determines what object gets the returned data if you set it in the switcher notify call
            const dataTableEditorObserver = new class extends TableDataEditorObserver { };
            dataTableEditorObserver.update = (): void => {
                this.update(Visual.settings);
            };

            const tableDataEditorSwitcher = new DataTableEditorSwitcher();
            tableDataEditorSwitcher.attach(dataTableEditorObserver);
            globalToolbar.add(tableDataEditorSwitcher);
            Visual.tableDataEditorSwitcher = tableDataEditorSwitcher;
        }

        const stackedChartSwitcher = new StackedChartSwitcher();
        const stackedChartObserver = new StackedChartObserver();
        stackedChartSwitcher.attach(stackedChartObserver);

        const accountSwitcher = new AccountSwitcher();

        globalToolbar.add(stackedChartSwitcher);
        globalToolbar.add(fieldsSwitcher);
        globalToolbar.add(settingsSwitcher);
        globalToolbar.add(accountSwitcher);
        globalToolbar.add(new AboutSwitcherOld(settings));

        globalToolbar.render(settings?.stackedChart);
        this.globalToolbar = globalToolbar;
    }

    private updateSettingsFromForm(formData: Map<string, any>) {
        for (const [key, value] of formData.entries()) {
            const splitKey = key.split(".");
            let setting = Visual.settings;

            if (splitKey.length > 1) {
                while (splitKey.length - 1 && (setting = setting[splitKey.shift()]));
            }

            setting[splitKey.shift()] = value;
        }

        Visual.settings.persist();
    }

    private async handleSignIn() {
        const signInObserver = new ActionObserver();
        signInObserver.update = async (data: string, action: string) => {
            if (action === ActionType.SignInSuccess) {
                return await this.onSignIn();
            }

            if (action === ActionType.SignOut) {
                return await this.onSignOut();
            }

        };
        signInService.attach(signInObserver);

        try {
            await signInService.silentSignIn();
        } catch (error) {
            if (process.env.DEBUG_LOG === "true") {
                console.debug(error.name, error);
            }

            if (!licensing.getOwnerUser()) {
                signInService.renderSignIn();
            }
        }
    }

    private refreshGlobalToolbar() {
        this.constructViewModelAndVisualUpdate(Visual.settings);
        this.globalToolbar?.render(Visual.settings?.stackedChart);
    }

    private async onSignIn() {
        await this.checkLicensing();

        const organizationId = licensing.getCurrentUser()?.organizationId;
        const userId = licensing.getCurrentUser()?.userId;
        this.loadOrganizationStyles(organizationId, userId);

        this.logSignInAnalytics();
        this.refreshGlobalToolbar();
    }

    private async onSignOut() {
        this.globalToolbar.hide();
        licensing.clearWatermark();
    }

    private checkWatermark() {
        if (!this.isLicenseFetchingDone) {
            return;
        }

        const ownerUser = licensing.getOwnerUser();
        const currentUser = licensing.getCurrentUser();

        if (!currentUser) {
            licensing.renderLicensingUnavailableWatermark();
            return;
        }

        // at the moment when visual is newly added it has no owner
        if (!ownerUser && currentUser && !currentUser.getLicense().hasLicense) {
            licensing.renderFreeLicenseWatermark();
            return;
        }

        if (licensing.freeViewerMode()) {
            licensing.renderFreeLicenseWatermark();
            return;
        }

        if (!signInService.isSignedIn()
            && ownerUser
            && !ownerUser.getLicense()?.hasLicense) {
            licensing.renderFreeLicenseWatermark();
            return;
        }

        // no watermark is required, clear any existing watermark
        licensing.clearWatermark();
    }

    private async checkLicensing() {
        try {
            await Promise.race([
                licensing.fetchUsers(),
                delay(5000).then(() => { throw new Error("Fetching licensing data timed out"); })
            ]);

            const currentUser = licensing.getCurrentUser();
            const ownerUser = licensing.getOwnerUser();

            if (!ownerUser && !currentUser?.getLicense()?.hasLicense) {
                licensing.renderNoLicenseOverlay();
                return;
            }

            if (!currentUser?.getLicense()?.hasLicense
                && currentUser?.isSameUserAs(ownerUser)
                && (!ownerUser?.lastDisplayedOverlay
                    || ownerUser?.lastDisplayedOverlay.getTime() < (new Date()).getTime() - (30 * 24 * 3600 * 1000)
                )) {

                if (ownerUser) {
                    ownerUser.lastDisplayedOverlay = new Date();
                }
                await OwnerUserInfo.setOwnerInfo(ownerUser);

                licensing.renderNoLicenseOverlay();
                return;
            }

        } catch (error) {
            if (error instanceof Error && error.message === "Fetching licensing data timed out") {
                console.error('Licensing service not available:', error.message);
            }
        } finally {
            this.isLicenseFetchingDone = true;
        }
    }

    private logSignInAnalytics() {
        const currentUser = licensing.getCurrentUser();
        if (!currentUser) {
            return;
        }

        Visual.isFirstTimeInsert = initializeAnalyticsReporting("charts", analyticsHandler, Visual.isFirstTimeInsert, this.dataHelper);
        analyticsHandler.identify({ userId: currentUser?.userId, organizationId: currentUser?.organizationId, plan: licensing.getPlanString() });
        initializeAnalyticsOptOut(analyticsHandler, currentUser, this.globalToolbar).finally(() => {
            // We wait for opt out to be correctly set before extracting existing analytics from current file
            extractExistingAnalyticsId(analyticsHandler);
        });
    }

    private getLocale(): string {
        return Office.context.contentLanguage || "en-US";
    }
}
