export enum ChartType {
    Waterfall,
    Area,
    Bar,
    Variance,
    Line,
    PlusMinus,
    PlusMinusDot,
    Pin,
    Advert, // TODO: Remove, refactor Ad Slide on Office & update in core as well
}

export enum LabelDensity {
    Full,
    None,
    Last,
    FirstLast,
    MinMax,
    FirstLastMinMax,
    Auto,
    Low,
    Medium,
    High,
}

export enum MarkerSize {
    Auto,
    Fixed
}

export enum DifferenceHighlightFromTo {
    Auto,
    FirstToLast,
    PenultimateToLast,
    LastToCorresponding,
    FirstToLastFC,
    PenultimateToLastFC,
    LastToCorrespondingFC,
    FirstToLastPL,
    PenultimateToLastPL,
    LastToCorrespondingPL,
    LastACToLastFC,
    MinToMax,
}

export enum Sort {
    None,
    Ascending,
    Descending,
}

export enum ShowTopNChartsOptions {
    Off,
    Items,
    Percentage,
}

export enum GridlineStyle {
    Solid,
    Dotted,
    Dashed,
}

export enum NegativeValuesFormat {
    Default,
    Parenthesis,
}

export enum DifferenceHighlightArrowStyle {
    ClosedArrow,
    RoundArrow,
    Dots,
    SingleDot,
    None,
    OpenArrow,
}

export enum AxisLabelDensity {
    All,
    FirstLast,
    EveryNth
}

export enum MultiplesAxisLabelsOptions {
    AllCharts,
    FirstChart,
    FirstRow,
    LastRow,
    FirstChartLastRow,
}

export enum ReferenceDisplayType {
    None,
    OverlappedColumn,
    Triangles,
}

export enum DotChartMarkerShape {
    Circle,
    Square,
    Diamond,
    Triangle
}

export enum DifferenceHighlightEllipse {
    Off,
    Ellipse,
    Circle
}

export enum SidebarChartSettings {
    invert,
    chartLayout,
    showGrandTotal,
    grandTotalLabel,
    limitOutliers,
    maxOutlierValue,
    minOutlierValue,
    referenceDisplayType,
    showAllForecastData,
    currentPeriodVarianceOptions,
    currentPeriodVarianceCondition,
    dayInMonthVarianceCondition,
    dayInWeekVarianceCondition,
    monthInQuarterVarianceCondition,
    monthInYearVarianceCondition,
    showVerticalCharts,
    handleNullsAsZeros
}
