import { Comparator } from "./library/types";
//todo: use these functions from the core
export function sortAscending<T>(array: T[], getProperty: (item: T) => number): void {
    const ascendingComparator: Comparator<T> = (a: T, b: T) => getProperty(a) - getProperty(b);
    array.sort(ascendingComparator);
}

export function sortDescending<T>(array: T[], getProperty: (item: T) => number): void {
    const descendingComparator: Comparator<T> = (a: T, b: T) => getProperty(b) - getProperty(a);
    array.sort(descendingComparator);
}

export function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}
