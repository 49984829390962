import { DataView, PrimitiveValue, DataViewValueColumnGroup, DataViewCategoryColumn } from "@zebrabi/table-data";

import { ChartType } from "./../enums";
import { DataPoint, OtherDataPoint, ChartData, CategoryData, ViewModel } from "./../interfaces";

import { ChartSettings } from "./../settings/chartSettings";
import * as viewModelHelpers from "./../library/viewModelHelpers";
import { getVariance, calculateRelativeDifference, getDataPointNonNullValue, calculateOutliers, getTopNCategoriesViewModelValues, getTopNCategoriesSingleSeriesValues } from "./viewModelHelperFunctions";

import * as styles from "./../library/styles";
import * as formatting from "./../library/formatting";
import { EMPTY, BLACK } from "./../library/constants";
import { LINE_BREAK_SEPARATOR, ABSOLUTE_RELATIVE, ABSOLUTE, ACTUAL, ACTUAL_ABSOLUTE, RELATIVE, ACTUAL_RELATIVE, ROWS_LAYOUT } from "./../consts";

import * as d3 from "../d3";
import { ChartsSelectionItem, ISelectionId } from "../SelectionService";

export function getSingleValueViewModel(viewModel: ViewModel, dataView: DataView) {
    const total = <number>dataView.values[0].values[0];
    const settings = viewModel.settings;

    const dataPoint: DataPoint = {
        category: EMPTY,
        value: total,
        color: settings.colorScheme.neutralColor,
        reference: total > 0 ? total : 0,
        isNegative: total < 0,
        isVariance: true,
        isHighlighted: false,
        secondSegmentValue: null,
        secondReference: null,
        tooltipMeasuresValues: []
    };
    viewModel.chartData.push({ group: EMPTY, dataPoints: [dataPoint], min: Math.min(0, total), max: Math.max(0, total), categoryLevelsCount: 0, isInverted: settings.invert, otherCategoryDataPoints: [] });
    return viewModel;
}

export function getCategoryViewModel(dataView: DataView, viewModel: ViewModel, locale: string): ViewModel {
    if (!dataView.values || !dataView.values.grouped) {
        const chartData: ChartData = {
            dataPoints: dataView.categories[0].values.map(c => createDataPoint(c?.toString ? c.toString() : "null")),
            otherCategoryDataPoints: [],
            group: null,
            min: 0,
            max: 0,
            categoryLevelsCount: 1,
            isInverted: false,
        };
        viewModel.chartData = [chartData];
        // viewModel.title = viewModelHelpers.getGroupsFields(dv);
    }
    else {
        const grouped = dataView.values.grouped();
        for (let i = 0; i < grouped.length; i++) {
            const chartData: ChartData = {
                dataPoints: dataView.categories[0].values.map(c => createDataPoint(c?.toString ? c.toString() : "null")),
                otherCategoryDataPoints: [],
                group: <string>grouped[i].name,
                min: 0,
                max: 0,
                categoryLevelsCount: 1,
                isInverted: false,
            };
            viewModel.chartData.push(chartData);
        }
        // viewModel.title = viewModelHelpers.getCategoriesAndGroups(dv, locale);
    }
    return viewModel;
}

// eslint-disable-next-line max-lines-per-function
export function getSingleSeriesViewModel(viewModel: ViewModel, dataView: DataView, hasCategoryHighlight: boolean, hasSeriesHighlight: boolean, locale: string) {
    const dataViewValues = dataView.values;
    const settings = viewModel.settings;
    const isAreaChart = settings.chartType === ChartType.Area;
    const categoriesToPlot = getCategoriesToPlotAndSetDrillDownTitleIfNeeded(dataView, viewModel, locale);
    const grouppedValues = dataViewValues.grouped();
    const hasSecondActualValues = settings.scenarioOptions.secondActualValueIndex !== null;
    const categoryLevelsCount = dataView.categories ? categoriesToPlot.length : 0;
    const isWaterfall = settings.chartType === ChartType.Waterfall && !(settings.stackedChart && grouppedValues.length > 1);

    const categoriesData: CategoryData[] = getCategoriesData(grouppedValues, categoriesToPlot, settings, true, viewModel, locale);

    for (let j = 0, len = grouppedValues.length; j < len; j++) {
        // if (hasSeriesHighlight && dataViewValues[j].highlights[0] == null) {
        //     continue;
        // }

        const chartData: ChartData = getChartData(grouppedValues[j], 0, 0, categoryLevelsCount, settings);
        let values = grouppedValues[j].values[0].values;
        let cumulative = 0;

        let chartCategoriesData = categoriesData;
        let chartOtherCategoriesData = null;

        if (settings.showTopNCategories && isWaterfall) {
            const { newValues, newCategoriesData, otherCategoriesData } = getTopNCategoriesSingleSeriesValues(settings.topNCategoriesToKeep, categoriesData, values, settings.topNOtherLabel);
            values = newValues;
            chartCategoriesData = newCategoriesData;
            chartOtherCategoriesData = otherCategoriesData;
        }

        if (chartOtherCategoriesData) {
            for (let i = 0; i < chartOtherCategoriesData.length; i++) {
                const value = i < values.length ? <number>values[i] : 0;

                const otherCategoryDataPoint: OtherDataPoint = {
                    category: chartOtherCategoriesData?.[i]?.category,
                    value: isWaterfall ? Math.abs(value) : value,
                };

                if (otherCategoryDataPoint.category) {
                    chartData.otherCategoryDataPoints.push(otherCategoryDataPoint);
                }
            }
        }

        const areaChartReference = getNumberOrNull(values[0]) !== null ? <number>values[0] : (settings.handleNullsAsZeros ? 0 : null);

        for (let i = 0; i < values.length; i++) {
            let value = i < values.length ? getNumberOrNull(values[i]) : 0;
            if (isWaterfall && chartCategoriesData[i].isInverted) {
                value = value * -1;
            }

            const color = isWaterfall ? styles.getVarianceColor(chartData.isInverted, value, settings.colorScheme) : settings.colorScheme.neutralColor;

            let originalCategoryIndex = i;
            if (isWaterfall && settings.showTopNCategories) {
                originalCategoryIndex = categoriesData.findIndex(c => c.category === chartCategoriesData[i].category);
            }

            const dataPoint: DataPoint = {
                category: chartCategoriesData[i].category,
                value: isWaterfall ? Math.abs(value) : value,
                color: color,
                reference: isAreaChart ? areaChartReference : (value > 0 ? value : 0),
                isNegative: value < 0,
                isVariance: true,
                isHighlighted: settings.isCategoryHighlighted(chartCategoriesData[i].category),
                secondSegmentValue: null,
                secondReference: null,
                secondActualValue: hasSecondActualValues ? <number>grouppedValues[j].values[settings.scenarioOptions.secondActualValueIndex].values[i] : null,
                startPosition: isWaterfall ? cumulative + (value < 0 ? 0 : value) : null,
                isCategoryInverted: chartCategoriesData[i].isInverted,
                isCategoryResult: chartCategoriesData[i].isResult,
                isCategoryFloatingResult: chartCategoriesData[i].isFloatingResult,
                isOther: chartCategoriesData[i].isOther,
                tooltipMeasuresValues: getTooltipMeasureValues(settings, viewModel, grouppedValues, j, originalCategoryIndex),
                commentsMeasuresValues: getCommentsMeasureValues(settings, viewModel, grouppedValues, j, originalCategoryIndex),
                selectionId: getSelectionId(chartCategoriesData[i].category)
            };

            if (isWaterfall && chartCategoriesData[i].isResult) {
                dataPoint.value = value;
                dataPoint.isVariance = false;
                dataPoint.isNegative = value < 0;
                dataPoint.color = settings.colorScheme.neutralColor;

                if (dataPoint.isCategoryFloatingResult) {
                    cumulative += value;
                } else { // for "typical, regular, standard" results
                    dataPoint.startPosition = value > 0 ? value : 0;
                    cumulative = value;
                }
            }
            else {
                cumulative += value;
            }

            chartData.dataPoints.push(dataPoint);
            if (isWaterfall) {
                if (cumulative > chartData.max) {
                    chartData.max = cumulative;
                }
                if (cumulative < chartData.min) {
                    chartData.min = cumulative;
                }
            }
        }

        if (!isWaterfall) {
            chartData.max = Math.max(0, Math.max(...values.map((v) => <number>v)));
            chartData.min = Math.min(0, Math.min(...values.map((v) => <number>v)));
        }

        if (isWaterfall && settings.showGrandTotal) {
            const totalDataPoint: DataPoint = {
                value: cumulative,
                category: settings.grandTotalLabel,
                color: settings.colorScheme.neutralColor,
                startPosition: cumulative > 0 ? cumulative : 0,
                isNegative: cumulative < 0,
                isVariance: false,
                isHighlighted: false,
                isCategoryResult: settings.isCategoryResult(settings.grandTotalLabel),
                //isCategoryFloatingResult: settings.isCategoryFloatingResult(settings.grandTotalLabel),
                reference: null,
                secondReference: null,
                secondSegmentValue: null,
                secondActualValue: null,
            };
            chartData.dataPoints.push(totalDataPoint);
        }

        if (hasSecondActualValues) {
            setSecondActualMinMax(chartData);
        }
        viewModel.chartData.push(chartData);
    }
    return viewModel;
}

function getTooltipMeasureValues(settings: ChartSettings, viewModel: ViewModel, grouppedValues: DataViewValueColumnGroup[], j: number, i: number) {
    const tooltipMeasuresValues = [];
    if (settings.scenarioOptions.tooltipsIndices.length > 0 && i >= 0) {
        viewModel.settings.scenarioOptions.tooltipsIndices.forEach(ix => {
            const dataViewValueColumn = grouppedValues[j].values[ix];
            const value = dataViewValueColumn.values[i];

            tooltipMeasuresValues.push(formatting.getFormattedTooltipMeasureLabel(
                <string>value,
                viewModelHelpers.getFormatDataView(dataViewValueColumn),
                viewModelHelpers.isDateTimeDataView(dataViewValueColumn)));
        });
    }
    return tooltipMeasuresValues;
}

function getCommentsMeasureValues(settings: ChartSettings, viewModel: ViewModel, grouppedValues: DataViewValueColumnGroup[], j: number, i: number) {
    if (!settings.scenarioOptions.commentsIndices || settings.scenarioOptions.commentsIndices.length === 0) {
        return null;
    }
    const commentMeasuresValues = [];
    if (settings.scenarioOptions.commentsIndices.length > 0 && i >= 0) {
        viewModel.settings.scenarioOptions.commentsIndices.forEach(ix => {
            commentMeasuresValues.push(grouppedValues[j].values[ix].values ? grouppedValues[j].values[ix].values[i] : null);
        });
    }

    return commentMeasuresValues;
}

// eslint-disable-next-line max-lines-per-function
export function getVarianceViewModel(viewModel: ViewModel, dataView: DataView, hasCategoryHighlight: boolean, hasSeriesHighlight: boolean,
    locale: string, combineTopNCategoriesIntoOthers: boolean = false) {
    const dataViewValues = dataView.values;
    const settings = viewModel.settings;
    const relativeValues = [];
    const grouppedValues = dataViewValues.grouped();
    const dataSeriesCount = grouppedValues[0].values.length;
    const categoriesToPlot = getCategoriesToPlotAndSetDrillDownTitleIfNeeded(dataView, viewModel, locale);
    const hasSecondActualValues = settings.scenarioOptions.secondActualValueIndex !== null;
    const categoryLevelsCount = dataView.categories ? categoriesToPlot.length : 0;

    const categoriesData: CategoryData[] = getCategoriesData(grouppedValues, categoriesToPlot, settings, false, viewModel, locale);

    for (let j = 0, len = grouppedValues.length; j < len; j++) {
        if (hasSeriesHighlight && grouppedValues[j].values[0].highlights[0] == null) {
            continue;
        }

        let values = grouppedValues[j].values[settings.scenarioOptions.valueIndex].values;
        let referenceValues = [];
        let secondSegmentValues = null;
        let secondReferenceValues = [];

        if (dataSeriesCount >= 2 && settings.scenarioOptions.referenceIndex !== null) {
            referenceValues = grouppedValues[j].values[settings.scenarioOptions.referenceIndex].values;
        }
        if (dataSeriesCount >= 2 && settings.scenarioOptions.secondValueIndex !== null) {
            secondSegmentValues = grouppedValues[j].values[settings.scenarioOptions.secondValueIndex].values;
        }
        if (dataSeriesCount >= 3 && settings.scenarioOptions.secondReferenceIndex !== null) {
            secondReferenceValues = grouppedValues[j].values[settings.scenarioOptions.secondReferenceIndex].values;
        }

        let chartCategoriesData = categoriesData;
        if (combineTopNCategoriesIntoOthers && settings.showTopNCategories) {
            const { newValues, newReferenceValues, newCategoriesData, newSecondReferenceValues, newSecondSegmentValues } = getTopNCategoriesViewModelValues(
                settings.topNCategoriesToKeep, categoriesData, values, referenceValues, secondReferenceValues, secondSegmentValues, settings.topNOtherLabel);
            values = newValues;
            referenceValues = newReferenceValues;
            chartCategoriesData = newCategoriesData;
            if (secondSegmentValues !== null) {
                secondSegmentValues = newSecondSegmentValues;
            }
            if (secondReferenceValues.length > 0) {
                secondReferenceValues = newSecondReferenceValues;
            }
        }

        const chartData: ChartData = getChartData(grouppedValues[j], 0, 0, categoryLevelsCount, settings);
        const lastNonNullValueIndex = getLastNonNullValueIndex(values);

        for (let i = 0, len = values.length; i < len; i++) {
            const color = settings.colorScheme.neutralColor;
            const valOrg = values[i];
            //let value = valOrg === "" || isNaN(valOrg) ? null : <number>values[i];
            let value = getNumberOrNull(valOrg);
            //if (value === "")
            let reference = dataSeriesCount === 1 ? 0 : getNumberOrNull(referenceValues[i]);
            if (settings.handleNullsAsZeros) {
                if (value === null && (settings.scenarioOptions.secondValueIndex === null || i < lastNonNullValueIndex)) {
                    value = 0;
                }
                if (reference === null) {
                    reference = 0;
                }
            }

            let originalCategoryIndex = i;
            if (combineTopNCategoriesIntoOthers && settings.showTopNCategories) {
                originalCategoryIndex = categoriesData.findIndex(c => c.category === chartCategoriesData[i].category);
            }

            const dataPoint: DataPoint = {
                category: chartCategoriesData[i].category,
                value: value,
                color: color,
                reference: reference !== undefined ? reference : null,
                isNegative: dataSeriesCount === 1 ? false : (value === null && secondSegmentValues !== null ? getNumberOrNull(secondSegmentValues[i]) : value) < (reference),
                isVariance: true,
                isHighlighted: settings.isCategoryHighlighted(chartCategoriesData[i].category),
                secondSegmentValue: dataSeriesCount >= 2 && settings.scenarioOptions.secondValueIndex !== null ? getNumberOrNull(secondSegmentValues[i]) : null,
                secondReference: dataSeriesCount >= 3 && settings.scenarioOptions.secondReferenceIndex !== null ? getNumberOrNull(secondReferenceValues[i]) : null,
                secondActualValue: hasSecondActualValues ? getNumberOrNull(grouppedValues[j].values[settings.scenarioOptions.secondActualValueIndex].values[i]) : null,
                isCategoryInverted: chartCategoriesData[i].isInverted,
                isOther: chartCategoriesData[i].isOther,
                tooltipMeasuresValues: getTooltipMeasureValues(settings, viewModel, grouppedValues, j, originalCategoryIndex),
                commentsMeasuresValues: getCommentsMeasureValues(settings, viewModel, grouppedValues, j, originalCategoryIndex),
                selectionId: getSelectionId(chartCategoriesData[i].category)
            };

            let relVariance = calculateRelativeDifference(dataPoint.value, dataPoint.reference);
            if (relVariance === null) {
                relVariance = calculateRelativeDifference(dataPoint.secondSegmentValue, dataPoint.reference);
            }
            if (relVariance !== null) {
                relativeValues.push(relVariance);
            }
            dataPoint.relativeVariance = relVariance;

            if (settings.scenarioOptions.secondReferenceIndex !== null) {
                dataPoint.secondReferenceRelativeVariance = calculateRelativeDifference(getDataPointNonNullValue(dataPoint), dataPoint.secondReference);
            }

            chartData.dataPoints.push(dataPoint);
        }

        const showAllFC = settings.showAllForecastData;
        chartData.max = Math.max(0, Math.max(...chartData.dataPoints.map(p => Math.max(p.reference, p.secondReference || 0, getValueForMinMaxCalc(p, showAllFC, true)))));
        chartData.min = Math.min(0, Math.min(...chartData.dataPoints.map(p => Math.min(p.reference, p.secondReference || 0, getValueForMinMaxCalc(p, showAllFC, false)))));
        let variances = chartData.dataPoints.map(d => getVariance(d, false, false));
        if (showAllFC) {
            variances = variances.concat(chartData.dataPoints.map(d => getVariance(d, false, true)));
        }
        chartData.maxVariance = Math.max(...variances, 0);
        chartData.minVariance = Math.min(...variances, 0);

        chartData.maxRelativeVariance = Math.max(...chartData.dataPoints.map((d) => d.relativeVariance), 0);
        chartData.minRelativeVariance = Math.min(...chartData.dataPoints.map((d) => d.relativeVariance), 0);
        calculateOutliers(chartData, settings);

        if (settings.scenarioOptions.secondReferenceIndex !== null) {
            let secondReferenceVariances = chartData.dataPoints.map(d => getVariance(d, true, false));
            if (showAllFC) {
                secondReferenceVariances = secondReferenceVariances.concat(chartData.dataPoints.map(d => getVariance(d, true, true)));
            }
            chartData.maxSecondReferenceVariance = Math.max(...secondReferenceVariances, 0);
            chartData.minSecondReferenceVariance = Math.min(...secondReferenceVariances, 0);
            chartData.maxSecondReferenceRelativeVariance = Math.max(...chartData.dataPoints.map((d) => d.secondReferenceRelativeVariance), 0);
            chartData.minSecondReferenceRelativeVariance = Math.min(...chartData.dataPoints.map((d) => d.secondReferenceRelativeVariance), 0);
        }

        const isPlusMinus = settings.chartType === ChartType.PlusMinus
            || settings.chartType === ChartType.Variance && settings.chartLayout === ABSOLUTE
            || settings.chartType === ChartType.Variance && settings.chartLayout === ABSOLUTE_RELATIVE && settings.multiplesLayoutType === ROWS_LAYOUT;
        const isPlusMinusDot = settings.chartType === ChartType.PlusMinusDot || settings.chartType === ChartType.Variance && settings.chartLayout === RELATIVE;
        const isNonOverlappedActualLayout = !settings.plotOverlappedReference && (settings.chartType === ChartType.Variance && settings.chartLayout === ACTUAL
            || settings.chartType === ChartType.Variance && settings.chartLayout === ACTUAL_ABSOLUTE
            || settings.chartType === ChartType.Variance && settings.chartLayout === ACTUAL_RELATIVE);
        if (isPlusMinus) {
            chartData.min = chartData.minVariance;
            chartData.max = chartData.maxVariance;
        }
        if (isPlusMinusDot) {
            chartData.min = chartData.minOutlierValue;
            chartData.max = chartData.maxOutlierValue;
        }
        if (isNonOverlappedActualLayout) {
            chartData.max = Math.max(0, ...chartData.dataPoints.map(p => Math.max(getValueForMinMaxCalc(p, showAllFC, true), p.secondReference || 0)));
            chartData.min = Math.min(0, ...chartData.dataPoints.map(p => Math.min(getValueForMinMaxCalc(p, showAllFC, false), p.secondReference || 0)));
        }
        if (hasSecondActualValues) {
            setSecondActualMinMax(chartData);
        }

        viewModel.chartData.push(chartData);
    }
    return viewModel;
}

// waterfall chart needs min/max variances set for the responsive layout
function calculateChartDataVariances(chartData: ChartData, showAllFC: boolean, settings: ChartSettings): void {
    const dataPoints = chartData.dataPoints.filter(d => d.isVariance);

    let variances = dataPoints.map(d => d.value * (d.isNegative ? -1 : 1));
    // todo: check if this is needed
    // if (showAllFC) {
    //     variances = variances.concat(dataPoints.map(d => getVariance(d, false, true)));
    // }
    chartData.maxVariance = Math.max(...variances, 0);
    chartData.minVariance = Math.min(...variances, 0);

    chartData.maxRelativeVariance = Math.max(...dataPoints.map((d) => d.relativeVariance), 0);
    chartData.minRelativeVariance = Math.min(...dataPoints.map((d) => d.relativeVariance), 0);
    calculateOutliers(chartData, settings);

    if (settings.scenarioOptions.secondReferenceIndex !== null) {
        let secondReferenceVariances = dataPoints.map(d => getVariance(d, true, false, true));
        if (showAllFC) {
            secondReferenceVariances = secondReferenceVariances.concat(dataPoints.map(d => getVariance(d, true, true, true)));
        }
        chartData.maxSecondReferenceVariance = Math.max(...secondReferenceVariances, 0);
        chartData.minSecondReferenceVariance = Math.min(...secondReferenceVariances, 0);
        chartData.maxSecondReferenceRelativeVariance = Math.max(...dataPoints.map((d) => d.secondReferenceRelativeVariance), 0);
        chartData.minSecondReferenceRelativeVariance = Math.min(...dataPoints.map((d) => d.secondReferenceRelativeVariance), 0);
    }
}

export function getValueForMinMaxCalc(p: DataPoint, showAllFCValues: boolean, isMax: boolean): number {
    if (showAllFCValues && p.value !== null && p.secondSegmentValue !== null) {
        return isMax ? Math.max(p.value, p.secondSegmentValue) : Math.min(p.value, p.secondSegmentValue);
    }
    else {
        return p.value === null ? p.secondSegmentValue : p.value;
    }
}

function getCategoriesData(grouppedValues: DataViewValueColumnGroup[], categoriesToPlot: DataViewCategoryColumn[], settings: ChartSettings,
    isSingleSeries: boolean, viewModel: ViewModel, locale: string): CategoryData[] {
    const categoriesData: CategoryData[] = [];
    for (let i = 0; i < grouppedValues[0].values[0].values.length; i++) {
        let categoryLabel = EMPTY;
        if (categoriesToPlot.length > 0) {
            if (viewModel.isDateCategories) {
                categoryLabel = formatting.fixDateTime(categoriesToPlot[0].values[i], categoriesToPlot[0].source.format, locale);
            }
            else {
                const categoryLabels = categoriesToPlot.map((c) => c.source && c.source.type && c.source.type.dateTime ?
                    formatting.fixDateTime(c.values[i], c.source.format, locale) : c.values[i]);
                if (settings.shouldPlotVerticalCharts()) {
                    categoryLabel = categoryLabels.join(" ");
                }
                else {
                    categoryLabel = categoryLabels.reverse().join(LINE_BREAK_SEPARATOR);
                }
            }
        }
        categoriesData.push({
            category: categoryLabel,
            isInverted: settings.isCategoryInverted(categoryLabel),
            isResult: settings.chartType === ChartType.Waterfall && isSingleSeries ? settings.isCategoryResult(categoryLabel) : null,
            isFloatingResult: settings.chartType === ChartType.Waterfall && isSingleSeries ? settings.isCategoryFloatingResult(categoryLabel) : null
        });
    }
    return categoriesData;
}
// eslint-disable-next-line max-lines-per-function
export function getWaterfallBridgeViewModel(viewModel: ViewModel, dataView: DataView, hasCategoryHighlight: boolean, hasSeriesHighlight: boolean, locale: string) {
    const dataViewValues = dataView.values;
    const settings = viewModel.settings;
    const grouppedValues = dataViewValues.grouped();
    const dataSeriesCount = grouppedValues[0].values.length;
    const categoriesToPlot = getCategoriesToPlotAndSetDrillDownTitleIfNeeded(dataView, viewModel, locale);
    const hasSecondActualValues = settings.scenarioOptions.secondActualValueIndex !== null;
    const categoryLevelsCount = dataView.categories ? categoriesToPlot.length : 0;

    const categoriesData: CategoryData[] = getCategoriesData(grouppedValues, categoriesToPlot, settings, false, viewModel, locale);

    for (let j = 0; j < grouppedValues.length; j++) {
        if (hasSeriesHighlight && grouppedValues[j].values[0].highlights[0] == null) {
            continue;
        }

        let values = grouppedValues[j].values[settings.scenarioOptions.valueIndex].values;
        let referenceValues = grouppedValues[j].values[settings.scenarioOptions.referenceIndex].values;
        let secondSegmentValues = dataSeriesCount >= 2 && settings.scenarioOptions.secondValueIndex !== null ?
            grouppedValues[j].values[settings.scenarioOptions.secondValueIndex].values : null;
        let secondReferenceValues = []; // used only for tooltips
        if (dataSeriesCount >= 3 && settings.scenarioOptions.secondReferenceIndex !== null) {
            secondReferenceValues = grouppedValues[j].values[settings.scenarioOptions.secondReferenceIndex].values;
        }

        let chartCategoriesData = categoriesData;
        let chartOtherCategoriesData = null;
        if (settings.showTopNCategories) {
            const { newValues, newReferenceValues, newCategoriesData, newSecondReferenceValues, newSecondSegmentValues, otherCategoriesData } = getTopNCategoriesViewModelValues(
                settings.topNCategoriesToKeep, categoriesData, values, referenceValues, secondReferenceValues, secondSegmentValues, settings.topNOtherLabel);
            values = newValues;
            referenceValues = newReferenceValues;
            chartCategoriesData = newCategoriesData;
            chartOtherCategoriesData = otherCategoriesData;

            if (secondSegmentValues !== null) {
                secondSegmentValues = newSecondSegmentValues;
            }
            if (secondReferenceValues.length > 0) {
                secondReferenceValues = newSecondReferenceValues;
            }
        }

        const sumReference = d3.sum(referenceValues, (v, i) => { return (chartCategoriesData[i].isInverted ? -1 : 1) * getNumberOrNull(<any>v); });
        let cumulative = sumReference;
        const chartData: ChartData = getChartData(grouppedValues[j], Math.min(0, cumulative), Math.max(0, cumulative), categoryLevelsCount, settings);

        const startPoint: DataPoint = {
            isCumulative: true,
            category: settings.referenceHeader,
            value: referenceValues.every(v => v === null) ? null : sumReference,
            startPosition: sumReference < 0 ? 0 : sumReference,
            reference: null,
            secondSegmentValue: null,
            color: settings.colorScheme.neutralColor,
            isNegative: sumReference < 0,
            isVariance: false,
            isHighlighted: false,
            secondActualValue: null,
        };
        chartData.dataPoints.push(startPoint);

        const lastNonNullValueIndex = getLastNonNullValueIndex(values);
        let secondSegmentValueUsed = false;

        for (let i = 0, len = Math.min(referenceValues.length, values.length); i < len; i++) {
            let reference = getNumberOrNull(referenceValues[i]);
            let value = getNumberOrNull(values[i]);
            const secondSegmentValue = secondSegmentValues !== null ? getNumberOrNull(secondSegmentValues[i]) : null;
            if (settings.handleNullsAsZeros) {
                if (value === null && (settings.scenarioOptions.secondValueIndex === null || i < lastNonNullValueIndex)) {
                    value = 0;
                }
                if (reference === null) {
                    reference = 0;
                }
            }

            let dpSecondSegmentValueUsed = false;
            if (value === null && secondSegmentValue !== null) {
                value = secondSegmentValue;
                secondSegmentValueUsed = true;
                dpSecondSegmentValueUsed = true;
            }

            let variance = value - reference;
            if (chartCategoriesData[i].isInverted) {
                variance = variance * -1;
            }
            const isNegative = variance < 0;

            let originalCategoryIndex = i;
            if (settings.showTopNCategories) {
                originalCategoryIndex = categoriesData.findIndex(c => c.category === chartCategoriesData[i].category);
            }

            const dataPoint: DataPoint = {
                category: chartCategoriesData[i].category,
                value: value === null && reference === null ? null : Math.abs(variance),
                originalValue: value,
                startPosition: value === null && reference === null ? null : cumulative + (isNegative ? 0 : (variance)),
                reference: reference,
                color: styles.getVarianceColor(chartData.isInverted, variance, settings.colorScheme),
                isNegative: isNegative,
                isVariance: true,
                isHighlighted: settings.isCategoryHighlighted(chartCategoriesData[i].category),
                secondSegmentValue: getNumberOrNull(values[i]) === null ? secondSegmentValue : null,
                secondReference: dataSeriesCount >= 3 && settings.scenarioOptions.secondReferenceIndex !== null ? getNumberOrNull(secondReferenceValues[i]) : null,
                secondActualValue: hasSecondActualValues ? getNumberOrNull(grouppedValues[j].values[settings.scenarioOptions.secondActualValueIndex].values[i]) : null,
                isCategoryInverted: chartCategoriesData[i].isInverted,
                isOther: chartCategoriesData[i].isOther,
                tooltipMeasuresValues: getTooltipMeasureValues(settings, viewModel, grouppedValues, j, originalCategoryIndex),
                commentsMeasuresValues: getCommentsMeasureValues(settings, viewModel, grouppedValues, j, originalCategoryIndex),
                selectionId: getSelectionId(chartCategoriesData[i].category),
                secondSegmentValueUsed: dpSecondSegmentValueUsed,
            };

            let relVariance = calculateRelativeDifference(value, reference);
            if (relVariance === null) {
                relVariance = calculateRelativeDifference(secondSegmentValue, reference);
            }
            // if (relVariance !== null) {
            //     relativeValues.push(relVariance);
            // }
            dataPoint.relativeVariance = relVariance;
            if (settings.scenarioOptions.secondReferenceIndex !== null) {
                dataPoint.secondReferenceRelativeVariance = calculateRelativeDifference(value ?? dataPoint.secondSegmentValue ?? null , dataPoint.secondReference);
            }

            cumulative += variance;
            if (cumulative > chartData.max) {
                chartData.max = cumulative;
            }
            if (cumulative < chartData.min) {
                chartData.min = cumulative;
            }
            chartData.dataPoints.push(dataPoint);
        }

        const endPoint: DataPoint = {
            isCumulative: true,
            category: secondSegmentValues !== null && secondSegmentValueUsed ? settings.secondValueHeader : settings.valueHeader,
            value: cumulative,
            reference: null,
            startPosition: cumulative < 0 ? 0 : cumulative,
            secondSegmentValue: secondSegmentValueUsed && secondSegmentValues !== null ? cumulative : null,
            color: settings.colorScheme.neutralColor,
            isNegative: cumulative < 0,
            isVariance: false,
            isHighlighted: false,
            secondActualValue: null,
        };
        chartData.dataPoints.push(endPoint);

        if (hasSecondActualValues) {
            setSecondActualMinMax(chartData);
        }

        if (chartOtherCategoriesData) {
            for (let i = 0; i < chartOtherCategoriesData.length; i++) {
                const otherCategoryDataPoint: OtherDataPoint = {
                    category: chartOtherCategoriesData?.[i]?.category,
                    value: null,
                };

                if (otherCategoryDataPoint.category) {
                    chartData.otherCategoryDataPoints.push(otherCategoryDataPoint);
                }
            }
        }

        calculateChartDataVariances(chartData, settings.showAllForecastData, settings);

        viewModel.chartData.push(chartData);
    }
    return viewModel;
}

function getChartData(grouppedValues: DataViewValueColumnGroup, min: number, max: number, categoryLevels: number, settings: ChartSettings): ChartData {
    const chartData: ChartData = {
        dataPoints: [],
        otherCategoryDataPoints: [],
        group: getGroup(grouppedValues),
        min: min,
        max: max,
        categoryLevelsCount: categoryLevels,
        isInverted: false,
        isGroupHighlighted: false,
        highlightGroupColor: "#0070C0"
    };
    chartData.isInverted = settings.getInvert(chartData.group);
    chartData.isGroupHighlighted = settings.isGroupHighlighted(chartData.group);
    chartData.highlightGroupColor = settings.getGroupHighlightColor(chartData.group);
    set2dMultiplesHeadersIfNeeded(grouppedValues, chartData);
    return chartData;
}

function getGroup(valueColumnGroup: DataViewValueColumnGroup): string {
    return valueColumnGroup && valueColumnGroup.name ? valueColumnGroup.name.toString() : null;
}

export function createDataPoint(category: string): DataPoint {
    return {
        category: category,
        value: null,
        reference: null,
        secondSegmentValue: null,
        secondReference: null,
        isNegative: false,
        isVariance: false,
        color: BLACK,
        isHighlighted: false,
        isCategoryInverted: false,
        selectionId: getSelectionId(category),
    };
}

function getCategoriesToPlotAndSetDrillDownTitleIfNeeded(dataView: DataView, viewModel: ViewModel, locale: string): DataViewCategoryColumn[] {
    if (!dataView.categories) {
        return [];
    }
    let categoriesToPlot = dataView.categories;
    const repeatingCategories = dataView.categories ? dataView.categories.filter(c => c.values.every(v => v === c.values[0])) : [];
    if (repeatingCategories.length > 0 && categoriesToPlot.length > 1) {
        if (repeatingCategories[repeatingCategories.length - 1].values.length === 1) {
            categoriesToPlot = [repeatingCategories[repeatingCategories.length - 1]];
        } else {
            categoriesToPlot = dataView.categories.filter(c => repeatingCategories.indexOf(c) === -1);
        }
        const drillDownTitle = repeatingCategories.map(c => c.values[0]).join(" ");
        viewModel.drillDownTitle = drillDownTitle;
    }
    else if (dataView.categories.length === 1 && dataView.categories[0].values.every(c => c instanceof Date)) {
        if (categoriesToPlot[0].values.every(c => (<Date>c).getFullYear() === (<Date>categoriesToPlot[0].values[0]).getFullYear())) {
            viewModel.drillDownTitle = (<Date>categoriesToPlot[0].values[0]).getFullYear().toString();
        }
        viewModel.isDateCategories = true;
    }
    return categoriesToPlot;
}

function set2dMultiplesHeadersIfNeeded(valueColumnGroup: DataViewValueColumnGroup, chartData: ChartData) {
    const firstGroup = getFirstGroupName(valueColumnGroup);
    if (firstGroup) {
        chartData.rowHeader = firstGroup;
        chartData.columnHeader = chartData.group;
        chartData.group = firstGroup + " - " + chartData.group;
    }
}

function getFirstGroupName(valueColumnGroup: DataViewValueColumnGroup): string {
    let groupName: string = null;
    // this is a hack that gets the group name of the firstly inserted group field if there are two group fields added
    try {
        groupName = <string>((<any>valueColumnGroup.identity).scopeId.And.Left.Comparison.Right.Literal.Value);
        // groupName = (<any>valueColumnGroup.identity).expr.left.right.value;
        if (groupName[0] === "'" && groupName[groupName.length - 1] === "'") {
            groupName = groupName.substring(1, groupName.length - 1);
        }
    }
    catch (e) {
        // console.log(e)
    }
    return groupName;
}

export function checkFor2dMultiplesGroups(viewModel: ViewModel) {
    const groupNames = viewModel.chartData.map(c => c.group);
    const uniqueGroupNames = groupNames.filter((v, i, a) => a.indexOf(v) === i);
    if (groupNames.length > uniqueGroupNames.length && groupNames.length % uniqueGroupNames.length === 0 && !viewModel.chartData[0].rowHeader) {
        viewModel.chartData.forEach((cd, i) => {
            cd.columnHeader = cd.group;
            const firstGroupIndex = Math.floor(i / uniqueGroupNames.length);
            const firstGroup = "Group" + (firstGroupIndex + 1);
            cd.rowHeader = firstGroup;
            cd.group = firstGroup + " - " + cd.group;
        });
    }
}

export function setSecondActualMinMax(chartData: ChartData) {
    const secondActualValues = chartData.dataPoints.filter(dp => dp.secondActualValue !== null).map(d => d.secondActualValue);
    chartData.minSecondActualValue = Math.min(0, ...secondActualValues);
    chartData.maxSecondActualValue = Math.max(0, ...secondActualValues);
}

export function getLastNonNullValueIndex(values: PrimitiveValue[]): number {
    let lastNonNullValueIndex = values.length - 1;
    while (lastNonNullValueIndex > 0 && (values[lastNonNullValueIndex] === null || values[lastNonNullValueIndex] === "")) {
        lastNonNullValueIndex--;
    }
    return lastNonNullValueIndex;
}

export function getNumberOrNull(value: PrimitiveValue) {
    return value === null || value === "" || isNaN(+value) ? null : Number(value);
}

function getSelectionId(category: string): ISelectionId {
    return {
        equals: (other: ChartsSelectionItem) => {
            return other.getKey() === category;
        },
        getKey: () => category
    };

}