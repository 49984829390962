import { ISelectionServiceProvider } from "@zebrabi/charts";
import { applyExcelSLicers } from "@zebrabi/data-helpers/slicers";
import { Visual } from "./visual";

export interface ISelectionId { //todo: check and possibly replace existing ISelectionId interface from legacy-library-common
    equals(other: ISelectionId): boolean;
    getKey(): string;
}

export type ChartsSelectionItem = ISelectionId;
export type ChartsSelection = ChartsSelectionItem[];

// interface ISelectionServiceProvider {
//     getSelection: () => ChartsSelection;
//     hasSelection: () => boolean;
//     select: (selection: ChartsSelection, multiSelect: boolean) => Promise<ChartsSelection>;
//     clear: () => Promise<Record<string, never>>;
//     showContextMenu: (selection: ChartsSelection, position: { x: number, y: number; }) => Promise<Record<string, never>>;
//     registerOnSelectCallback?: (callback: (selection: ChartsSelection) => void) => void;
// }

export class OfficeSelectionService implements ISelectionServiceProvider {

    selectedCategories: string[] = [];
    isFilterApplied(): boolean {
        return this.selectedCategories?.length > 0;
    };

    isFilteringEnabled(): boolean {
        return this.isExcel;
    }

    constructor(private isExcel: boolean) { }

    public getSelection(): ChartsSelection {
        return this.selectedCategories.map((category) => {
            return {
                equals: (other: ChartsSelectionItem) => {
                    return other.getKey() === category;
                },
                getKey: () => category
            };
        });
    }

    public hasSelection(): boolean {
        return this.selectedCategories?.length > 0;
    }

    public select(selection: ChartsSelection, multiSelect: boolean): Promise<ChartsSelection> {
        console.log("select", selection, multiSelect);
        if (this.isFilteringEnabled) {
            return applyExcelSLicers(selection?.[0]?.getKey(), Visual.dataView?.categories?.[0]?.source?.displayName, multiSelect)
                .then((result) => {
                    this.selectedCategories = result;
                    return this.getSelection();
                });
        } else {
            return Promise.resolve([]);
        }
    }

    public clear(): Promise<Record<string, never>> {
        if (this.isFilterApplied()) {
            return applyExcelSLicers("", Visual.dataView?.categories?.[0]?.source?.displayName, false)
                .then((result) => {
                    this.selectedCategories = result;
                    return {};
                });
        } else {
            return Promise.resolve({});
        }
    }

    public showContextMenu(selection: ISelectionId[], position: { x: number; y: number }): Promise<Record<string, never>> {
        return Promise.resolve({});
    }

    //registerOnSelectCallback?: (callback: (selection: ChartsSelection) => void) => void;
}
