import BaseSwitcher from "@zebrabi/global-toolbar-old/components/global-toolbar/BaseSwitcher";
import GlobalToolbar from "@zebrabi/global-toolbar-old/components/GlobalToolbar";
import { toolbarGroupHeader } from "../templates/toolbarGroupHeader";
import { CLICK } from "../../library/constants";
import { Visual } from "../../visual";

abstract class BaseSwitcherWithHeaderOld extends BaseSwitcher {

    protected getVisual() {
        return Visual;
    }

    /**
     * @override to add a header for all action containers
     * @param switcherMenuContainer
     * @param active
     */
    createMenuItems(switcherMenuContainer: HTMLElement, active: string = ""): void {
        this.createMenuHeader(switcherMenuContainer);
        super.createMenuItems(this.createMenuBody(switcherMenuContainer), active);
    }

    /**
     * generate the header with the title and bind events
     * @param switcherMenuContainer
     */
    createMenuHeader(switcherMenuContainer: HTMLElement) {
        const range = document.createRange();
        const groupHeaderFragment = range.createContextualFragment(toolbarGroupHeader);
        const title = groupHeaderFragment.querySelector(".title");
        const closeButton = groupHeaderFragment.querySelector(".close");

        title.textContent = this.toolbarOptions.elementName;
        closeButton.addEventListener(CLICK, (e) => {
            this.notifyAction(GlobalToolbar.TOOLBAR_CLOSE, ""); // let's notify action close toolbar
            e.preventDefault();
        });

        switcherMenuContainer.append(groupHeaderFragment);
    }

    protected createMenuBody(switcherMenuContainer: HTMLElement): HTMLElement {
        const body = document.createElement("div");
        body.classList.add("body");
        return switcherMenuContainer.appendChild(body);
    }

    public setScrollPosition(scrollPosition: number) {
        this.switcherScrollPosition = scrollPosition;
        const body = this.switcherContainer.querySelector(".body");

        if (body) {
            this.switcherContainer.querySelector(".body").scrollTop = this.switcherScrollPosition;
        }
    }

    public getScrollPosition(): number {
        const body = this.switcherContainer.querySelector(".body");

        if (body) {
            return this.switcherContainer?.querySelector(".body").scrollTop;
        }

        return 0;
    }
}

export default BaseSwitcherWithHeaderOld;
