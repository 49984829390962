
import { DataView, DataViewMetadataColumn, DataViewValueColumn, DataViewValueColumnGroup } from "@zebrabi/table-data";

import * as translations from "@zebrabi/legacy-library-common/translations";
import { EMPTY } from "./constants";

export function getCategoriesAndGroups(dv: DataView, locale: string): string {
    let categories = getCategoriesFields(dv);
    let groups = getGroupsFields(dv);
    const byTranslation = translations.getTranslatedByString(locale);
    if (groups !== EMPTY) {
        groups = ` ${byTranslation} ${groups}`;
    }
    if (categories !== EMPTY) {
        categories = getCategoriesPrefix(groups, categories, byTranslation) + categories;
    }
    return `${groups}${categories}`;
}

function getCategoriesFields(dv: DataView): string {
    // let categoryColumns = dv[0].metadata.columns.filter(c => c.roles && c.roles["Category"] === true);
    // if (categoryColumns && categoryColumns.length > 0) {
    //     return getFields(categoryColumns);
    // }
    // else if (dv[0].matrix && dv[0].matrix.rows && dv[0].matrix.rows.root) {
    //     return getFieldsFromISQExpr(dv[0].matrix.rows.root.childIdentityFields);
    // }
    if (dv.rowFields.length) {
        return dv.rowNames.join(", ");
    }
    else {
        return EMPTY;
    }
}

function getGroupsFields(dv: DataView): string {
    // let groupColumns = dv[0].metadata.columns.filter(c => c.roles && c.roles["Group"] === true);
    // if (groupColumns && groupColumns.length > 0) {
    //     return getFields(groupColumns);
    // }
    // else if (dv[0].matrix && dv[0].matrix.columns && dv[0].matrix.columns.root) {
    //     return getFieldsFromISQExpr(dv[0].matrix.columns.root.childIdentityFields);
    // }
    if (dv.columnFields.length) {
        return dv.columnNames.join(", ");
    }
    else {
        return EMPTY;
    }
}

function getCategoriesPrefix(groups: string, categories: string, byTranslation: string): string {
    return groups !== null && groups !== EMPTY && categories.indexOf(",") === -1 ? ", " : ` ${byTranslation} `;
}

export function isDateTimeDataView(dvValueColumnGroup: DataViewValueColumn): boolean {
    return dvValueColumnGroup.source.type.dateTime;
}

export function getFormatDataView(dvValueColumnGroup: DataViewValueColumn): string {
    return dvValueColumnGroup.source.format;
}

export function isDateTimeDataColumn(metadataColumn: DataViewMetadataColumn): boolean {
    return metadataColumn?.type?.dateTime === true;
}

export function getDataColumnFormat(metadataColumn: DataViewMetadataColumn): string {
    return metadataColumn.format;
}

export function isPercentageDataDataView(dvValueColumnGroup: DataViewValueColumnGroup, valuesIndex: number = 0): boolean {
    const source = dvValueColumnGroup && dvValueColumnGroup.values && dvValueColumnGroup.values[valuesIndex] ? dvValueColumnGroup.values[valuesIndex].source : null;
    return source && source.format && source.format.indexOf("%") !== -1 ? true : false;
}

export function isPercentageDataDataViewMatrix(valueSources: DataViewMetadataColumn[], valuesIndex: number = 0): boolean {
    return valueSources && valueSources.length > valuesIndex && valueSources[valuesIndex]?.format?.indexOf("%") >= 0;
}
